import { T, always, cond, equals, find, has, propEq } from "ramda"
import styled from "@emotion/styled"
import { NinjaResponseError } from "js/includes/common/types"
import {
  fetchJson,
  user,
  showErrorMessage,
  ninjaReportError,
  localized,
  localizationKey,
  reportErrorAndShowMessage,
  isFeatureEnabled,
  getOs,
  showSuccessMessage,
  isMacDevice,
} from "js/includes/common/utils"
import { getOrganization, getNodeProductList as _getNodeProductList } from "js/includes/common/client"
import { faPlay } from "@fortawesome/pro-solid-svg-icons"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import { vmGuestDataspecs } from "js/state/actions/virtualization/dataspecs"
import { dataspecHash } from "js/state/actions/virtualization/common"
import { colors } from "js/includes/common/theme"
import { isTeamViewerAppEnabled } from "js/includes/common/utils/teamviewer"
import { isSplashtopAppEnabled } from "js/includes/common/utils/splashtop"

export const BaseStyledMenuContainer = styled.div`
  min-width: 250px;
  ul {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    li {
      display: flex;
      min-height: 40px;
      &:hover {
        background-color: ${colors.ninjaWhite};
      }
      button {
        font-size: 14px;
        flex: 1 1;
        display: inline-flex;
        padding: 12px 10px;
        white-space: nowrap;
        &:hover {
          cursor: pointer;
          text-decoration: none;
          outline: none;
        }
      }
    }
  }
`

export const StyledMenuContainer = styled(BaseStyledMenuContainer)`
  ul li button {
    color: ${({ theme }) => theme.color.text};
  }
`

const getRunnerActionName = jobType => {
  return cond([
    [equals("ScriptAutomation"), always("RUN_AUTOMATION_SCRIPT")],
    [equals("NativeAutomation"), always("RUN_AUTOMATION_NATIVE")],
    [equals("BinaryInstallAutomation"), always("RUN_AUTOMATION_INSTALL_APPLICATION")],
    [equals("BinaryRunAutomation"), always("RUN_AUTOMATION_RUN_APPLICATION")],
    [T, always(jobType)],
  ])(jobType)
}

export const fitPopoverStyle = ({ leftOffset = -15 } = {}) => ({
  defaultStyle,
  wrapperBoundaries,
  wrapperHeight,
  popoverHeight,
  left,
}) => {
  const top = wrapperBoundaries.top + wrapperHeight / 2 - 25
  const isOverflowingBottom = top + popoverHeight >= window.innerHeight
  return {
    ...defaultStyle,
    ...(isOverflowingBottom ? { bottom: "10px", top: undefined } : { top: top + "px" }),
    left: left + leftOffset + "px",
  }
}

export const getNodeProductList = async nodeId => {
  try {
    return await _getNodeProductList(nodeId)
  } catch (error) {
    reportErrorAndShowMessage(error)
    return []
  }
}

const getNodeProduct = (nodeProducts = [], productCode) => find(propEq("productCode", productCode), nodeProducts) || {}

export const getConnectWiseControlProperties = nodeProducts => {
  const { licenseKey, enabled } = getNodeProduct(nodeProducts, "CONNECTWISE") || {}
  return { licenseKey, enabled }
}

export const launchConnectWiseControl = (url, licenseKey, device) => {
  const { nodeClass, systemName } = device
  const deviceName =
    isMacDevice(nodeClass) && systemName.endsWith(".local") ? systemName.replace(".local", "") : systemName

  try {
    window.open(
      `${url}/Host#Access/All%20Machines/${encodeURIComponent(deviceName)}/${licenseKey}/Join`,
      "_blank",
      "noreferrer",
    )
  } catch (error) {
    reportErrorAndShowMessage(error)
  }
}

const checkSplashTopEnabled = (customerResponse, device) => {
  const splashTopCustomerConfig = customerResponse?.content?.splashtop
  const { nodeClass, content } = device
  return has("enabled", content?.splashtop)
    ? content?.splashtop?.enabled
    : splashTopCustomerConfig?.[nodeClass]?.enabled
}

const checkNinjaConnectEnabled = (customerResponse, device) => {
  const ninjaConnectCustomerConfig = customerResponse?.content?.ninjaConnect
  const { nodeClass, content } = device
  if (!ninjaConnectCustomerConfig?.enabled) {
    return false
  }
  if (!ninjaConnectCustomerConfig?.[nodeClass]?.enabled) {
    return false
  }
  return has("enabled", content?.ninjaConnect)
    ? content?.ninjaConnect?.enabled
    : ninjaConnectCustomerConfig?.[nodeClass]?.enabled
}

export const getSplashTopAndNinjaConnectProperties = async (device, nodeProducts) => {
  const os = getOs()
  const { clientId, isUp } = device
  const customerResponse = await getOrganization(clientId)
  const splashTopConnectProperties = await getSplashTopConnectProperties(device, nodeProducts, customerResponse)
  const showNinjaConnect =
    checkNinjaConnectEnabled(customerResponse, device) &&
    isUp &&
    isFeatureEnabled("ninja_control") &&
    user("canConnectToNinjaConnect") &&
    (os === "windows" || os === "osx")
  return { ...splashTopConnectProperties, showNinjaConnect }
}

const getSplashTopConnectProperties = async (device, nodeProducts, customerResponse) => {
  if(!isSplashtopAppEnabled()) return {
    showSplashTopConnect: false,
    splashtopSuuid: undefined
  }

  try {
    const { id } = device
    const splashTopStatusResponse = await fetchJson(`/query/splashtopstatus/${id}`)
    if (splashTopStatusResponse && splashTopStatusResponse.resultCode !== "SUCCESS") {
      throw new NinjaResponseError(splashTopStatusResponse)
    } else {
      const { installed } = splashTopStatusResponse
      const licenseKey = getNodeProduct(nodeProducts, "SPLASHTOP")?.licenseKey
      const splashTopEnabled = checkSplashTopEnabled(customerResponse, device)
      return {
        showSplashTopConnect: installed && user("canConnectToSplashtop") && licenseKey && splashTopEnabled,
        splashtopSuuid: licenseKey,
      }
    }
  } catch (error) {
    showErrorMessage(localized("Error"))
    ninjaReportError(error)
  }
}

export const getTeamViewerConnectProperties = async device => {
  if(!isTeamViewerAppEnabled()) return false

  try {
    const { id, isUp } = device
    const teamViewerConfig = await fetchJson(`/webapp/teamviewerconfig/${id}`)
    if (teamViewerConfig.resultCode === "SUCCESS") {
      const { divisionEnabled, enabled, mode } = teamViewerConfig.config
      const showTeamViewer = isUp && user("canConnectToTeamViewer") && divisionEnabled && enabled
      return showTeamViewer && mode === "auto"
    }
    return false
  } catch (error) {
    showErrorMessage(localized("Error"))
    ninjaReportError(error)
  }
}

export const runAdhocJob = async (job, nodes, fromDeviceSearch = false, jobType) => {
  const nodesList = nodes && !Array.isArray(nodes) ? [nodes] : nodes
  const nodesCount = fromDeviceSearch
    ? window.store.getState().deviceSearch.actionRunnerParams.selectedCount
    : nodesList.length
  const buttonPressed = await ShowMessageDialog({
    icon: { icon: faPlay, type: "info" },
    title: localizationKey("Run Automation?"),
    message: () =>
      nodesCount > 1
        ? localized("Are you sure you want to run this automation on {{total}} devices?", { total: nodesCount })
        : localized("Are you sure you want to run this automation on this device?"),
    buttons: [
      { id: true, label: localizationKey("Yes") },
      { id: false, label: localizationKey("No") },
    ],
  })
  if (buttonPressed) {
    try {
      if (fromDeviceSearch) {
        const payload = { action: getRunnerActionName(jobType), data: { job } }

        await job.saveJob(null, false, null, payload)
        showSuccessMessage(localized("Automation Queued"))
      } else {
        const jobNodes = nodesList?.map(({ id: nodeId }) => ({
          nodeId,
          jobs: [job],
        }))

        await job.saveJob(null, false, jobNodes)
        showSuccessMessage(localized("Automation Queued"))
      }
    } catch (error) {
      if (!error.isDevicesCacheExpiredError) {
        showErrorMessage(localized("Error creating/updating jobs"))
      }
    }
  }
}

export const getNode = async id => {
  try {
    const response = await fetchJson(`/node/${id}`)
    if (response.resultCode === "SUCCESS") {
      return response.node
    } else {
      throw new NinjaResponseError(response)
    }
  } catch (error) {
    showErrorMessage(localized("Error"))
    ninjaReportError(error)
  }
}

export const getNodeDash = (id, dataspecs, since) => {
  try {
    return fetchJson(`/webapp/nodedash/${id}`, {
      options: {
        method: "POST",
        body: JSON.stringify({
          since,
          dataspecs,
        }),
      },
    })
  } catch (error) {
    showErrorMessage(localized("Error"))
    ninjaReportError(error)
  }
}

export const getGuestSystemForVmGuest = async id => {
  const nodeDash = await getNodeDash(id, vmGuestDataspecs, 0)
  const dataSpecs = nodeDash?.node ? dataspecHash(nodeDash.node.datasets) : {}
  return dataSpecs["vm-guest-system"] ? dataSpecs["vm-guest-system"].datapoints[0].data : {}
}
