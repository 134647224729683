//@flow
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { HashRouter as Router } from "react-router-dom"
import { faArrowCircleUp } from "@fortawesome/pro-solid-svg-icons"
import Backbone from "backbone"
import $ from "jquery"
import { clone } from "ramda"
import _ from "underscore"

import ApplicationContainer from "js/includes/application"
import loadServerDefaults from "js/includes/common/services/serverDefaultsLoader"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import StrictMode from "js/includes/components/StrictMode"
import WampConnection from "js/infrastructure/wamp/WampConnection"
import { setFavoriteTabs } from "js/state/actions/general/favoriteTabs"
import { getHealthStatus } from "js/state/actions/general/healthStatus"
import { requestDownloadableProducts, requestSetProducts } from "js/state/actions/general/products"
import { setRecentTabs } from "js/state/actions/general/recentTabs"
import { requestNinjaPSAConfigs } from "js/state/actions/ninjaPSA"
import { getRemoteSupportConfig } from "js/state/actions/remoteSupport"
import { requestTicketingConfigs } from "js/state/actions/ticketing"
import { setupGettingStarted } from "js/state/actions/session/gettingStarted"
import { initialState as brandingNodeDefault } from "js/state/reducers/websiteBranding/brandingNode"
import { initialState as hostnameDefault } from "js/state/reducers/websiteBranding/hostname"

import "@ninjamsp/ninja-flot"
import "bootstrap"
import "bootstrap-datepicker"
import "@ninjamsp/ninja-bootstrap"
import "icheck"
import "intl-tel-input/build/js/intlTelInput-jquery"
import "react-virtualized/styles.css"

import { initJobTypeCollection } from "./includes/common/_jobs"
import {
  addIndicator,
  changeAppUrl,
  debugLog,
  fetch,
  fetchJson,
  initBrandingState,
  initMessageListener,
  isAppInQaMode,
  isFeatureEnabled,
  isFullScreenMode,
  isNinjaPSAEnabledFromSettings,
  isSuperAdmin,
  isTicketingEnabledFromSettings,
  localizationKey,
  localized,
  logoutWithMessage,
  preventBackspaceNavigation,
  PubSub,
  removeIndicator,
  ninjaReportError,
  requestAndShowApplicationAnnouncement,
  sendSessionConfig,
  setupAppVisibilityHook,
  setupPopoverHide,
  showApplicationFailedToLoadDialog,
} from "./includes/common/utils"
import ModelBase from "./includes/editors/Common/ModelBase"
import { APIKeyInfoCollection, PsaTicketTemplateInfoCollection } from "./infrastructure/backbone/InfoCollections"
import { APIKeyInfoModel, PsaTicketTemplateInfoModel, UserInfoModel } from "./infrastructure/backbone/InfoModels"
import { getServiceNowConnection } from "./state/actions/psa/ServiceNow/connection"
import { handleOpenGuideTooltipOnAppInit } from "js/state/actions/general/guideTooltips"
import { requestSamlConfig } from "./state/actions/identityProvider/identityProvider"
import { setDarkMode } from "./state/actions/websiteBranding/brandingNode"
import { ThemeProvider } from "./ThemeProvider"
import { requestQuickConnectConfigs } from "js/state/actions/quickConnect/configurations"
import { isNinjaRemoteAppEnabled } from "js/includes/common/utils/ninjaRemote"

export const VersionModel = ModelBase.extend({
  defaults: {
    version: process.env.REACT_APP_VERSION,
  },
  url: "/infrastructure/version",

  initialize: function() {
    _.bindAll(this, "update")
  },

  parse: function(response) {
    if (response.resultCode === "SUCCESS") {
      return response
    }
    console.log("Error parsing version information")
  },
})

const TimeZoneModel = ModelBase.extend({
  url: "/division/timezone",

  parse: function(response) {
    if (response.resultCode === "SUCCESS") {
      return response.timeZone
    }
    console.log("Error parsing timeZone")
  },
})

const InfoCollectionContainer = ModelBase.extend({
  defaults: {
    users: [],
    reports: [],
    psaTicketTemplates: [],
    APIKeys: [],
  },

  relations: [
    {
      type: Backbone.HasMany,
      key: "psaTicketTemplates",
      relatedModel: PsaTicketTemplateInfoModel,
      collectionType: PsaTicketTemplateInfoCollection,
    },
    {
      type: Backbone.HasMany,
      key: "APIKeys",
      relatedModel: APIKeyInfoModel,
      collectionType: APIKeyInfoCollection,
    },
  ],

  async load() {
    return this.get("psaTicketTemplates").fetch()
  },
})

const MainApplication = ModelBase.extend({
  defaults: {
    publicURL: process.env.PUBLIC_URL,
    activeRoute: "/",
    version: {},
    session: {},
    timeZone: {},
    infoCollectionContainer: {},
    isLoading: true,
    versionCheckInterval: 900000, //15 minutes
    runtimeVersion: process.env.REACT_APP_VERSION,
    websiteBranding: {
      brandingNode: brandingNodeDefault,
      hostname: hostnameDefault,
    },
    pauseTimers: false,
  },

  relations: [
    {
      type: Backbone.HasOne,
      key: "version",
      relatedModel: VersionModel,
    },
    {
      type: Backbone.HasOne,
      key: "timeZone",
      relatedModel: TimeZoneModel,
    },
    {
      type: Backbone.HasOne,
      key: "infoCollectionContainer",
      relatedModel: InfoCollectionContainer,
    },
  ],

  initialize() {
    this.set({
      isFullScreenMode: isFullScreenMode(),
      isSuperAdmin: isSuperAdmin(),
      qaMode: isAppInQaMode(),
    })

    _.bindAll(this, "logout")
  },

  async logout(e) {
    e.preventDefault()
    logoutWithMessage()
  },
  reload(locationHash, search) {
    // Although a reload will refresh the application,
    // if the history AKA router isn't stopped, it will prevent a reload before '/' actually loads
    if (Backbone && Backbone.history) {
      Backbone.history.stop()
    }
    window.location.hash = locationHash || "/"

    const _search = typeof search === "string" ? search : undefined
    changeAppUrl({ search: _search })

    window.location.reload(true)
  },

  scheduleVersionCheck() {
    debugLog(`Scheduling next version check in ${this.get("versionCheckInterval")} milliseconds`)
    this.versionCheckTimeout = setTimeout(() => {
      this.startVersionCheck()
    }, this.get("versionCheckInterval"))
  },

  async startVersionCheck() {
    debugLog("Starting version check")
    this.scheduleVersionCheck()

    const requestHeaders = new Headers()
    requestHeaders.append("pragma", "no-cache")
    requestHeaders.append("cache-control", "no-cache")
    try {
      const response = await fetch(`/app-version.txt?cacheKey=${Date.now()}`, {
        headers: requestHeaders,
        useSessionPrefix: false,
        options: {
          cache: "no-store",
        },
      })
      const version = await response.text()
      if (version === this.get("runtimeVersion")) return

      debugLog("Cancelling the next version check")
      clearTimeout(this.versionCheckTimeout)

      const buttonClicked = await ShowMessageDialog({
        icon: { icon: faArrowCircleUp, type: "info" },
        title: localizationKey(
          "A new version of the user interface is available. Would you like to upgrade now? You can always click 'Later' and then choose to refresh your browser at any time to apply the update.",
        ),
        buttons: [
          { id: "YES", label: localizationKey("Yes") },
          { id: "LATER", label: localizationKey("Later") },
        ],
      })

      switch (buttonClicked) {
        case "YES":
          this.reload()
          break
        case "LATER":
          this.scheduleVersionCheck()
          break
        default:
      }
    } catch (error) {}
  },
  async loadInfoCollectionContainer() {
    await window.store.dispatch(getServiceNowConnection())
    await this.get("infoCollectionContainer").load()
  },
  async load(sessionProperties) {
    initMessageListener()

    window.nodeClasses = sessionProperties.nodeClasses
    window.psaConnect.set("psaType", sessionProperties.psaType)
    window.psaConnect.set(
      "enabled",
      sessionProperties.divisionConfig?.find(config => config.name === "PSA")?.enabled ?? false,
    )

    try {
      const { recents, favorites } = await fetchJson("/webapp/recents-favorites")
      window.store.dispatch(setRecentTabs(recents))
      window.store.dispatch(setFavoriteTabs(favorites))
    } catch (error) {
      ninjaReportError(error)
    }

    await window.store.dispatch(initBrandingState)

    window.store.dispatch(handleOpenGuideTooltipOnAppInit())

    this.set({
      session: new Backbone.Model(sessionProperties),
      timeZone: new TimeZoneModel({ value: sessionProperties.timezone }),
    })

    this.set("websiteBranding", clone(window.store.getState().websiteBranding))
    this.set("showBranding", window.store.getState().application.isBranded)

    preventBackspaceNavigation()

    // Setup the loading indicator and show the application
    addIndicator($("#main-container"), localized("Loading"))

    if (this.get("isFullScreenMode")) {
      this.setApplicationReady()
    } else {
      window.moment.locale(window.store.getState().application.locale)

      try {
        const { store } = window

        const user = UserInfoModel.findOrCreate(this.get("session").get("user"))
        this.set("user", user)

        if (window.Sentry) {
          window.Sentry.configureScope(scope => {
            scope.setUser({
              id: user.get("uid"),
              ...(user.isNinja() ? { email: user.get("email") } : {}),
            })
            scope.setTag("divisionUid", sessionProperties.divisionUid)
            scope.setTag("version", process.env.REACT_APP_VERSION)
          })
        }

        await Promise.all([
          loadServerDefaults(),
          store.dispatch(requestSetProducts()),
          store.dispatch(requestDownloadableProducts()),
          requestAndShowApplicationAnnouncement(),
          this.get("version").fetch(),
          this.loadInfoCollectionContainer(),
          window.teamViewerSystemConfig.load(),
          // we need to know if remote support is enabled to show in sidebar
          store.dispatch(getRemoteSupportConfig()),
          store.dispatch(getHealthStatus()),
          store.dispatch(
            requestTicketingConfigs({ fetchBoards: true, fetchBoardsConfiguration: true, fetchSMTPHealth: true }),
          ),
          store.dispatch(requestNinjaPSAConfigs()),
          store.dispatch(requestSamlConfig()),
          ...(isNinjaRemoteAppEnabled() ? [store.dispatch(requestQuickConnectConfigs())] : []),
        ])

        //Setup GettingStarted
        const {
          user: {
            content: { gettingStartedSteps },
          },
        } = window.store.getState().session
        store.dispatch(setupGettingStarted(gettingStartedSteps))

        // Setup popover hide
        setupPopoverHide()

        // We need the appUser info for job messages
        initJobTypeCollection()

        const session = this.get("session").get("connect")

        window.wamp = new WampConnection({
          sessionConnectUrl: session.url,
          sessionConnectAuthUser: session.authUser,
          applicationEnvironment: window.store.getState().application.environment,
          userUid: this.get("user").get("uid"),
          sessionDivisionUid: this.get("session").get("divisionUid"),
          deleteAuthUser: () => delete this.get("session").get("connect").authUser,
        })

        const isDarkModeFeatureEnabled = isFeatureEnabled("dark_mode")
        if (isDarkModeFeatureEnabled) {
          await store.dispatch(setDarkMode(isDarkModeFeatureEnabled))
        }
        const themeName = window.store.getState().application.theme

        const { default: theme } = await import(
          `js/includes/common/theme/${themeName}/${isDarkModeFeatureEnabled ? "dark.js" : "light.js"}`
        )
        window.theme = theme
        ReactDOM.render(
          <StrictMode>
            <Provider store={window.store}>
              <ThemeProvider initialTheme={theme}>
                <Router>
                  <ApplicationContainer />
                </Router>
              </ThemeProvider>
            </Provider>
          </StrictMode>,
          document.getElementById("root"),
        )

        if (process.env.NODE_ENV === "production") {
          this.startVersionCheck()
        }

        window.wamp
          .subscribeToChannel("announcements", ([subTopic]) => {
            switch (subTopic) {
              case "APPLICATION_ANNOUNCEMENT":
                requestAndShowApplicationAnnouncement()
                break
              default:
                throw new Error(`Invalid subTopic: "${subTopic}"`)
            }
          })
          .catch(ninjaReportError)

        isTicketingEnabledFromSettings() &&
          window.wamp
            .subscribeToChannel("ticketing", (...params) => {
              PubSub.publish("ticketing-internal", params[1])
            })
            .catch(ninjaReportError)

        isNinjaPSAEnabledFromSettings() &&
          window.wamp
            .subscribeToChannel("ninja-psa", (...params) => {
              PubSub.publish("ninja-psa-internal", params[1])
            })
            .catch(ninjaReportError)

        setupAppVisibilityHook()
        this.setApplicationReady()
      } catch (error) {
        ninjaReportError(error)
        showApplicationFailedToLoadDialog()
      }
    }
  },
  setApplicationReady() {
    Backbone.history.start()
    // Remove the loading mask when the application is done loading
    this.set("isLoading", false)
    // Force app to remove indicator when done loading
    removeIndicator($("#main-container"))

    if (!isFullScreenMode()) {
      sendSessionConfig()
    }
  },
})

export default MainApplication
