import { filter, test } from "ramda"

import {
  downloadFile,
  fetch,
  fetchJson,
  isPositiveInteger,
  localizationKey,
  localized,
  reportErrorAndShowMessage,
  showSuccessMessage,
  upload,
  isAndroidMDMFeatureEnabled,
  ninjaReportError,
} from "js/includes/common/utils"
import { getCompanyDisplayName } from "./divisionConfig"
import { mdmBaseEndpoint } from "./mdm"

// Android Enterprise
export const fetchEnterpriseInformation = () => {
  if (!isAndroidMDMFeatureEnabled) return []
  return fetchJson(`${mdmBaseEndpoint}/enterprise/info`)
}

export const fetchEnterpriseConnection = connectionId => fetchJson(`${mdmBaseEndpoint}/enterprise/${connectionId}`)

export const updateEnterpriseConnectionNames = async ({ connectionId, connectionName, enterpriseDisplayName }) =>
  fetchJson(`${mdmBaseEndpoint}/enterprise/${connectionId}`, {
    options: {
      method: "PATCH",
      body: JSON.stringify({
        enterpriseDisplayName,
        connectionName,
      }),
    },
  })

export const deleteAndroidEnterprise = id =>
  fetchJson(`${mdmBaseEndpoint}/enterprise/${id}`, { options: { method: "DELETE" } })

// TODO: Use actual endpoint when server changes are done (NJ-70732)
export const getAndroidEnterpriseDevices = id => fetchJson(`${mdmBaseEndpoint}/enterprise/${id}/devices`)

// APNs
export const getCSRFile = async ({ pushCertificateId } = {}) => {
  const [{ companyDisplayName }, { csrUid, encodedCsr }] = await Promise.all([
    getCompanyDisplayName(),
    pushCertificateId
      ? fetchJson(`/mdm/certificate/apn/${pushCertificateId}/csr`)
      : fetchJson("/mdm/certificate/apn-csr", {
          options: {
            method: "POST",
          },
        }),
  ])
  const blob = new Blob([encodedCsr], { type: "text/plain" })
  downloadFile(URL.createObjectURL(blob), `${companyDisplayName.value}.csr`)
  return { csrUid }
}

export const fetchAPNConnections = () => fetchJson("/mdm/certificate/apn")

export const createAPNConnection = ({ file, appleId, name, csrUid }) => {
  const formData = new FormData()
  formData.append("file", file)
  formData.append("appleId", appleId)
  formData.append("name", name)
  formData.append("csrUid", csrUid)
  return fetchJson("/mdm/certificate/apn", {
    options: { method: "POST", body: formData },
    includeJsonContentType: false,
  })
}

export const editAPNConnection = ({ name, pushCertificateId }) => {
  return fetchJson(`/mdm/certificate/apn/${pushCertificateId}`, {
    options: { method: "PATCH", body: JSON.stringify({ name }) },
  })
}

export const renewAPNCertificate = ({ pushCertificateId, file }) => {
  const formData = new FormData()
  formData.append("file", file)
  return fetchJson(`/mdm/certificate/apn/${pushCertificateId}/renew`, {
    options: { method: "PATCH", body: formData },
    includeJsonContentType: false,
  })
}

export const deleteAPNCertificate = ({ pushCertificateId }) =>
  fetchJson(`/mdm/certificate/apn/${pushCertificateId}`, { options: { method: "DELETE" } })

export const getAPNCertificateFromId = async (connectionId: int) => {
  try {
    return await fetchJson(`/mdm/certificate/apn/${connectionId}`)
  } catch (error) {
    if (error?.resultCode !== "push_certificate_not_found") {
      reportErrorAndShowMessage(error, localizationKey("Error fetching APN certificate"))
    }
    throw error
  }
}

// ADE
const BASE_ADE_URL = `${mdmBaseEndpoint}/enrollment/ade`
const ADE_URL = `${BASE_ADE_URL}/tokens`

export const fetchADEProfiles = () => fetchJson(ADE_URL)

export const getADEProfileConfiguration = async () => {
  try {
    const { ade } = await fetchJson("/mdm/defaults/apple")
    // TODO: Integrate other platforms support when BE will be ready
    // for now only iOS items will be supported
    return filter(item => test(/ios/i, item.platforms))(ade.setupItems)
  } catch (error) {
    throw error
  }
}

export const createADEProfile = ({
  file,
  defaultClientId,
  defaultLocationId,
  defaultNodeRole,
  pushCertificateId,
  adeTokenName,
}) => {
  const body = new FormData()
  body.append("file", file)
  if (defaultClientId) {
    body.append("defaultClientId", defaultClientId)
  }
  if (defaultLocationId) {
    body.append("defaultLocationId", defaultLocationId)
  }
  body.append("defaultNodeRole", defaultNodeRole)
  body.append("pushCertificateId", pushCertificateId)
  body.append("adeTokenName", adeTokenName)

  return fetchJson(ADE_URL, { options: { method: "POST", body }, includeJsonContentType: false })
}

const getADEPublicKeyFile = () => fetch(`${BASE_ADE_URL}/public-certificate`)

export const downloadPublicKeyFile = async () => {
  const [{ companyDisplayName }, downloadResponse] = await Promise.all([getCompanyDisplayName(), getADEPublicKeyFile()])
  const file = await downloadResponse.blob()

  downloadFile(URL.createObjectURL(file), `${companyDisplayName.value}_ade_public_key.pem`)
  return { id: "OK" }
}

export const renewADEProfile = ({ file, adeTokenId }) => {
  const body = new FormData()
  body.append("file", file)

  return fetchJson(`${ADE_URL}/${adeTokenId}/renew`, {
    options: { method: "PATCH", body },
    includeJsonContentType: false,
  })
}

export const updateADEProfile = ({ adeTokenId, adeTokenName }) => {
  return fetchJson(`${ADE_URL}/${adeTokenId}`, {
    options: { method: "PATCH", body: JSON.stringify({ name: adeTokenName }) },
  })
}

export const deleteADEProfile = id => fetchJson(`${ADE_URL}/${id}`, { options: { method: "DELETE" } })

export const saveADEProfileConfiguration = (adeTokenId, profile, idProfile) => {
  const url = `/mdm/enrollment/ade/tokens/${adeTokenId}/profiles`
  const method = idProfile ? "PATCH" : "POST"
  return fetchJson(url, {
    options: {
      method,
      body: JSON.stringify(profile),
    },
  })
}

export const assignDefaultConfiguration = defaultConfigurationData => {
  return fetchJson(`${ADE_URL}/default-configuration`, {
    options: {
      method: "POST",
      body: JSON.stringify(defaultConfigurationData),
    },
  })
}

export const getADETokenFromNodeId = async (nodeId: int) => {
  try {
    return await fetchJson(`${BASE_ADE_URL}/devices/${nodeId}/token`)
  } catch (error) {
    if (error?.resultCode !== "ade_token_not_found") {
      reportErrorAndShowMessage(error, localizationKey("Error fetching ADE token"))
    }
    throw error
  }
}

export const deleteVPPToken = id =>
  fetchJson(`/mdm/connections/vpp/content-tokens/${id}`, {
    options: {
      method: "DELETE",
    },
  })
export const uploadVPPToken = body => {
  return upload(`/mdm/connections/vpp/content-tokens`, { method: "POST", body })
}

export const renewVPPToken = (body, id) => {
  return upload(`/mdm/connections/vpp/content-tokens/${id}/renew`, { method: "POST", body })
}

export const editVPPToken = (contentTokenName, id) => {
  return fetchJson(`/mdm/connections/vpp/content-tokens/${id}`, {
    options: {
      method: "PATCH",
      body: JSON.stringify({
        contentTokenName,
      }),
    },
  })
}

export const getVPPTokens = async () => {
  try {
    return await fetchJson("/mdm/connections/vpp/content-tokens")
  } catch (error) {
    if (error?.resultCode === "content_token_not_found") {
      return []
    }
    throw error
  }
}

export const getVPPToken = async id => {
  try {
    return await fetchJson(`/mdm/connections/vpp/content-tokens/${id}`)
  } catch (error) {
    if (error?.resultCode !== "content_token_not_found") {
      reportErrorAndShowMessage(error, localizationKey("Error fetching content token"))
    }
    return null
  }
}

export const syncVPPToken = async id => {
  try {
    await fetchJson(`/mdm/connections/vpp/content-tokens/${id}/sync`, {
      options: {
        method: "POST",
      },
    })
    showSuccessMessage(localized("Successfully synchronized for ABM"))
    return true
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error syncing content token"))
    return false
  }
}

export const syncVPPTokens = async (contentTokenIds, showMessage = true) => {
  try {
    await fetchJson("/mdm/connections/vpp/content-tokens/sync", {
      options: {
        method: "POST",
        body: JSON.stringify({ contentTokenIds }),
      },
    })
    if (showMessage) {
      showSuccessMessage(localized("Successfully synchronized for ABM"))
    }
    return true
  } catch (error) {
    if (showMessage) {
      reportErrorAndShowMessage(error, localizationKey("Error syncing content token"))
    } else {
      ninjaReportError(error)
    }
    return false
  }
}

export const putAssignedVPPOrgs = (id, organizationIds) => {
  return fetchJson(`/mdm/connections/vpp/content-tokens/${id}/organizations`, {
    options: {
      method: "PUT",
      body: JSON.stringify({
        organizationIds: organizationIds,
      }),
    },
  })
}

export const updateVPPDefaultProperty = (id, value) => {
  return fetchJson(`/mdm/connections/vpp/content-tokens/${id}/default`, {
    options: {
      method: "PATCH",
      body: JSON.stringify({
        default: value,
      }),
    },
  })
}

export const syncVPPLicensedAppToken = async (tokenId, withAlertMessage) => {
  if (!isPositiveInteger(tokenId)) return false
  try {
    await fetchJson(`/mdm/connections/vpp/content-tokens/${tokenId}/sync`, {
      options: {
        method: "POST",
      },
    })

    if (withAlertMessage) {
      showSuccessMessage(localized("Successfully synchronized"))
    }
    return true
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error syncing assets"))
    return false
  }
}

export const getVPPTokenFromOrgId = async (orgId: int) => {
  try {
    return await fetchJson(`/mdm/connections/vpp/organizations/${orgId}/content-token`)
  } catch (error) {
    if (error?.resultCode !== "content_token_not_found") {
      reportErrorAndShowMessage(error, localizationKey("Error fetching content token"))
    }
    throw error
  }
}
