import {
  CircleNumberOneSolidIcon,
  CircleNumberOneLightIcon,
  CircleNumberTwoSolidIcon,
  CircleNumberTwoLightIcon,
  CircleNumberThreeSolidIcon,
  CircleNumberThreeLightIcon,
  CircleNumberFourSolidIcon,
  CircleNumberFourLightIcon,
  CircleNumberFiveSolidIcon,
  CircleNumberFiveLightIcon,
  CircleNumberSixSolidIcon,
  CircleNumberSixLightIcon,
  MemoIconLight,
  QuestionCircleIconLight,
  RoadLightIcon,
  UserRoleMembersIconLight,
  WindowsIcon,
  AppleIcon,
  LinuxIcon,
  AndroidIcon,
  PlusIcon,
  IosIconSolid,
  ListIconLight,
  PlayIconLight,
  ExternalLinkIcon,
  AppsIconLight,
  BullhornIconLight,
  SiteMapIconLight,
  LaptopMobileIconLight,
  PlayIconRegular,
  BriefCaseIcon,
  PlugIcon,
  UserRoleMembersIcon,
  FileCodeIconLight,
} from "@ninjaone/icons"
import { Body, UnstyledTabsContent } from "@ninjaone/components"
import {
  localizationKey,
  DOJO_SECTION_PREFIX,
  fetchJson,
  localized,
  showErrorMessage,
  localizedWith,
  DOJO_COMMUNITY_PREFIX,
  user,
  isSystemAdmin,
  DOJO_CATEGORIES_PREFIX,
  ZENDESK_DOJO_URL,
  DOJO_ARTICLES_PREFIX,
  isAppleMDMFeatureEnabled,
  isAndroidMDMFeatureEnabled,
} from "js/includes/common/utils"
import Link from "js/includes/components/Link"
import GettingStartedStepOne from "js/includes/GetStartedPage/GettingStartedStepOne"
import GettingStartedStepThree from "js/includes/GetStartedPage/GettingStartedStepThree"
import showGettingStartedVideoModal from "js/includes/GetStartedPage/utils/modalVideo"
import { openQuickMenu } from "js/state/actions/general/quickMenus"
import StepCompletedOnVisit from "js/includes/GetStartedPage/components/StepCompletedOnVisit"
import {
  technicianVideo,
  endUserVideo,
  windowsInstallVideo,
  macInstallVideo,
  linuxInstallVideo,
  androidInstallVideo,
  appleInstallVideo,
  createPolicyVideo,
  createOrgVideo,
} from "./media/GetStartedMedia"

export const getStartedTabs = {
  stepOne: {
    stepNumber: 1,
    StepNumberSolidIcon: CircleNumberOneSolidIcon,
    token: localizationKey("Watch intro video"),
    StepNumberLightIcon: CircleNumberOneLightIcon,
    StepIcon: PlayIconRegular,
    renderer: ({ currentStepId, onTaskCompleted }) => (
      <UnstyledTabsContent value="stepOne">
        <GettingStartedStepOne activeStep={currentStepId} onTaskCompleted={onTaskCompleted} />
      </UnstyledTabsContent>
    ),
  },
  stepTwo: {
    stepNumber: 2,
    StepNumberSolidIcon: CircleNumberTwoSolidIcon,
    token: localizationKey("Add Technicians and End-users"),
    StepNumberLightIcon: CircleNumberTwoLightIcon,
    StepIcon: UserRoleMembersIcon,
    renderer: ({ currentStepId, onTaskCompleted }) => (
      <UnstyledTabsContent value="stepTwo">
        <StepCompletedOnVisit
          onTaskCompleted={onTaskCompleted}
          activeStep={currentStepId}
          permissionRequired={{
            name: localized("Users"),
            allowed: user("canCreateUsers") || user("canCRUDEndUserSharing"),
          }}
          resources={addTechniciansAndEndUsers()}
          titleToken={localizationKey("Add Technicians and End-users")}
          descriptionToken={localizationKey(
            "There are two types of users in NinjaOne: Technicians and End-Users. Technicians have access to the core platform to monitor the health of devices. End-Users have access to the self-service portal to submit tickets and access their device(s) remotely.",
          )}
          externalLinks={addTechniciansAndEndUsersMoreArticles}
          value="stepTwo"
        />
      </UnstyledTabsContent>
    ),
  },
  stepThree: {
    stepNumber: 3,
    StepNumberSolidIcon: CircleNumberThreeSolidIcon,
    token: localizationKey("Add devices"),
    StepNumberLightIcon: CircleNumberThreeLightIcon,
    StepIcon: LaptopMobileIconLight,

    validateStepRequirements: async () => {
      try {
        if (!user("canCreateDevices")) return true
        const devices = await fetchJson("/query/devices", {
          method: "POST",
        })
        return devices.length > 0
      } catch (error) {
        showErrorMessage(localized("Error updating get started steps"))
        return false
      }
    },
    renderer: ({ currentStepId, onTaskCompleted, stepsCompleted }) => (
      <UnstyledTabsContent value="stepThree">
        <GettingStartedStepThree
          onTaskCompleted={onTaskCompleted}
          activeStep={currentStepId}
          permissionRequired={{ name: localized("Devices"), allowed: user("canCreateDevices") }}
          stepsCompleted={stepsCompleted}
          deviceVideos={[
            ...computerVideos,
            ...(isAppleMDMFeatureEnabled() ? appleVideos() : []),
            ...(isAndroidMDMFeatureEnabled() ? androidVideos() : []),
          ]}
          externalLinks={addDevicesArticleLinks}
        />
      </UnstyledTabsContent>
    ),
  },
  stepFour: {
    stepNumber: 4,
    StepNumberSolidIcon: CircleNumberFourSolidIcon,
    token: localizationKey("Set up policies"),
    StepNumberLightIcon: CircleNumberFourLightIcon,
    StepIcon: BriefCaseIcon,
    renderer: ({ currentStepId, onTaskCompleted }) => (
      <UnstyledTabsContent value="stepFour">
        <StepCompletedOnVisit
          onTaskCompleted={onTaskCompleted}
          activeStep={currentStepId}
          permissionRequired={{ name: localized("Policies"), allowed: user("canCreatePolicies") }}
          resources={setUpPoliciesLinks}
          titleToken={localizationKey("Set up policies")}
          descriptionToken={localizationKey(
            "Policies centralize the decision-making for how a group of devices are monitored, managed, patched, configured, secured, and backed-up. Every device in NinjaOne is managed via a policy to drive standardization and ensure efficient device management.",
          )}
          value="stepFour"
        />
      </UnstyledTabsContent>
    ),
  },
  stepFive: {
    stepNumber: 5,
    StepNumberSolidIcon: CircleNumberFiveSolidIcon,
    token: localizationKey("Enable apps and integrations"),
    StepNumberLightIcon: CircleNumberFiveLightIcon,
    StepIcon: PlugIcon,
    renderer: ({ currentStepId, onTaskCompleted }) => (
      <UnstyledTabsContent value="stepFive">
        <StepCompletedOnVisit
          onTaskCompleted={onTaskCompleted}
          activeStep={currentStepId}
          permissionRequired={{ name: localized("Apps"), allowed: isSystemAdmin() }}
          resources={enableAppsAndIntegrationLinks}
          titleToken={localizationKey("Enable apps and integrations")}
          descriptionToken={localizationKey(
            "NinjaOne includes many NinjaOne-developed add-ons and third party integrations. Enabling and configuring applications during setup will ensure you have the tools you need from the start.",
          )}
          externalLinks={learnAboutOurApps}
          value="stepFive"
        >
          <Body textWrap as="span">
            {localizedWith(
              "For third-party integrations, go to our Integrations Documentation page <%link>Integrations Documentation page<%>",
              {
                link: ({ localizedText }) => (
                  <Link href={`${DOJO_SECTION_PREFIX}/360004175031`}>
                    {localizedText} <ExternalLinkIcon size="xs" />
                  </Link>
                ),
              },
            )}
          </Body>
        </StepCompletedOnVisit>
      </UnstyledTabsContent>
    ),
  },
  stepSix: {
    stepNumber: 6,
    StepNumberSolidIcon: CircleNumberSixSolidIcon,
    token: localizationKey("Manage organizations"),
    StepNumberLightIcon: CircleNumberSixLightIcon,
    StepIcon: SiteMapIconLight,
    renderer: ({ currentStepId, onTaskCompleted }) => (
      <UnstyledTabsContent value="stepSix">
        <StepCompletedOnVisit
          onTaskCompleted={onTaskCompleted}
          activeStep={currentStepId}
          permissionRequired={{ name: localized("Organizations"), allowed: user("canCreateCustomers") }}
          resources={manageOrganizations}
          titleToken={localizationKey("Manage organizations")}
          descriptionToken={localizationKey(
            "Organizations group devices that have similar management needs. Organizations centralize policy assignment, device discovery, end-user management, credential storage, and infrastructure management decision-making.",
          )}
          value="stepSix"
        />
      </UnstyledTabsContent>
    ),
  },
}

export const getStartedQuickLinks = [
  {
    labelToken: localizationKey("Dojo / Help center"),
    link: ZENDESK_DOJO_URL,
    Icon: QuestionCircleIconLight,
  },
  {
    labelToken: localizationKey("Product roadmap"),
    link: "https://portal.productboard.com/nnzqj6ynxtn4ocxuxr4fefjw/tabs/1-next-release",
    Icon: RoadLightIcon,
  },
  {
    labelToken: localizationKey("NinjaOne community"),
    link: DOJO_COMMUNITY_PREFIX,
    Icon: UserRoleMembersIconLight,
  },
  {
    labelToken: localizationKey("Release notes"),
    link: `${DOJO_SECTION_PREFIX}/201742066`,
    Icon: MemoIconLight,
  },
  {
    labelToken: localizationKey("News"),
    link: `${DOJO_CATEGORIES_PREFIX}/201851566`,
    Icon: BullhornIconLight,
  },
  {
    labelToken: localizationKey("API documentation"),
    link: "/apidocs/",
    Icon: FileCodeIconLight,
  },
]

export const getStartedAdditionalResourcesForCriticalSetup = [
  {
    labelToken: localizationKey("System requirements"),
    link: `${DOJO_ARTICLES_PREFIX}/218365623`,
  },
  {
    labelToken: localizationKey("SSO"),
    link: `${DOJO_ARTICLES_PREFIX}/360059862912`,
  },
  {
    labelToken: localizationKey("Whitelisting"),
    link: `${DOJO_ARTICLES_PREFIX}/211406886`,
  },
  {
    labelToken: localizationKey("Mass deployment"),
    link: `${DOJO_ARTICLES_PREFIX}/207077733`,
  },
]

export const getStartedAdditionalResourcesForLevelUp = [
  {
    labelToken: localizationKey("Branding"),
    link: `${DOJO_ARTICLES_PREFIX}/360021397911`,
  },
  {
    labelToken: localizationKey("Scripting and Automations"),
    link: `${DOJO_ARTICLES_PREFIX}/360016094492`,
  },
  {
    labelToken: localizationKey("Device search and groups"),
    link: `${DOJO_ARTICLES_PREFIX}/360016094512`,
  },
  {
    labelToken: localizationKey("Push notifications"),
    link: `${DOJO_ARTICLES_PREFIX}/4406766167821`,
  },
  {
    labelToken: localizationKey("Scheduled tasks"),
    link: `${DOJO_ARTICLES_PREFIX}/360016094572`,
  },
  {
    labelToken: localizationKey("Network monitoring"),
    link: `${DOJO_ARTICLES_PREFIX}/207021723`,
  },
  {
    labelToken: localizationKey("Reporting"),
    link: `${DOJO_ARTICLES_PREFIX}/360025675292`,
  },
  {
    labelToken: localizationKey("Remote tools"),
    link: `${DOJO_ARTICLES_PREFIX}/360030662192`,
  },
]

export const setUpPoliciesLinks = [
  {
    LeftIcon: MemoIconLight,
    titleToken: localizationKey("Read documentation"),
    RightIcon: ExternalLinkIcon,
    onClick: () => {},
    dojoUrl: `${DOJO_SECTION_PREFIX}/15628001035405`,
  },
  {
    LeftIcon: PlayIconLight,
    titleToken: localizationKey("Watch Policies video (2 min)"),
    onClick: () =>
      showGettingStartedVideoModal({
        modalTitleToken: localizationKey("Policies"),
        modalDescriptionRenderer: () => (
          <Link href={`${DOJO_SECTION_PREFIX}/15628001035405`}>
            {localized("Read full documentation about Policies")} <ExternalLinkIcon size="sm" />
          </Link>
        ),
        video: createPolicyVideo,
      }),
  },
  {
    LeftIcon: ListIconLight,
    titleToken: localizationKey("Go to Policies"),
    onClick: () => (window.location.hash = "#/administration/policies/agent"),
  },
]

export const enableAppsAndIntegrationLinks = [
  {
    LeftIcon: AppsIconLight,
    titleToken: localizationKey("Open Apps quick menu"),
    onClick: () => window.store.dispatch(openQuickMenu("apps")),
  },
  {
    LeftIcon: ListIconLight,
    titleToken: localizationKey("Go to Apps"),
    onClick: () => (window.location.hash = "#/administration/apps"),
  },
]

export const learnAboutOurApps = [
  {
    titleToken: localizationKey("Learn about NinjaOne apps: "),
    items: [
      {
        labelToken: localizationKey("Ticketing"),
        link: `${DOJO_SECTION_PREFIX}/4567434594573`,
      },
      {
        labelToken: localizationKey("Backup"),
        link: `${DOJO_SECTION_PREFIX}/360009961552`,
      },
      {
        labelToken: localizationKey("Documentation"),
        link: `${DOJO_ARTICLES_PREFIX}/360061218431`,
      },
      {
        labelToken: localizationKey("Mobile Device Management (MDM)"),
        link: `${DOJO_SECTION_PREFIX}/20070956583309`,
      },
    ],
  },
]

export const computerVideos = [
  {
    id: 1,
    Icon: WindowsIcon,
    titleToken: localizationKey("Windows computers"),
    duration: "1:55",
    onClick: () =>
      showGettingStartedVideoModal({
        modalTitleToken: localizationKey("Windows computers"),
        modalDescriptionRenderer: () => (
          <Link href={`${DOJO_ARTICLES_PREFIX}/360003497591`}>
            {localized("Read full documentation about Windows computers")} <ExternalLinkIcon size="sm" />
          </Link>
        ),
        video: windowsInstallVideo,
      }),
  },
  {
    id: 2,
    Icon: AppleIcon,
    titleToken: localizationKey("Mac computers"),
    duration: "2:01",
    onClick: () =>
      showGettingStartedVideoModal({
        modalTitleToken: localizationKey("Mac computers"),
        modalDescriptionRenderer: () => (
          <Link href={`${DOJO_ARTICLES_PREFIX}/4404968856461`}>
            {localized("Read full documentation about Mac computers")} <ExternalLinkIcon size="sm" />
          </Link>
        ),
        video: macInstallVideo,
      }),
  },
  {
    id: 3,
    Icon: LinuxIcon,
    titleToken: localizationKey("Linux computers"),
    duration: "2:00",
    onClick: () =>
      showGettingStartedVideoModal({
        modalTitleToken: localizationKey("Linux computers"),
        modalDescriptionRenderer: () => (
          <Link href={`${DOJO_ARTICLES_PREFIX}/4404954737421`}>
            {localized("Read full documentation about Linux computers")} <ExternalLinkIcon size="sm" />
          </Link>
        ),
        video: linuxInstallVideo,
      }),
  },
]

export const appleVideos = () => [
  {
    id: 4,
    Icon: IosIconSolid,
    titleToken: localizationKey("Apple mobile devices"),
    duration: "1:00",
    onClick: () =>
      showGettingStartedVideoModal({
        modalTitleToken: localizationKey("Apple mobile devices"),
        modalDescriptionRenderer: () => (
          <Link href={`${DOJO_ARTICLES_PREFIX}/20028476165389`}>
            {localized("Read full documentation about Apple mobile devices")} <ExternalLinkIcon size="sm" />
          </Link>
        ),
        video: appleInstallVideo,
      }),
  },
]

export const androidVideos = () => [
  {
    id: 5,
    Icon: AndroidIcon,
    titleToken: localizationKey("Android mobile devices"),
    duration: "1:19",
    onClick: () =>
      showGettingStartedVideoModal({
        modalTitleToken: localizationKey("Android mobile devices"),
        modalDescriptionRenderer: () => (
          <Link href={`${DOJO_ARTICLES_PREFIX}/20070381996301`}>
            {localized("Read full documentation about Android mobile devices")} <ExternalLinkIcon size="sm" />
          </Link>
        ),
        video: androidInstallVideo,
      }),
  },
]

export const addDevicesArticleLinks = [
  {
    labelToken: localizationKey("Create a Cloud Monitor"),
    link: `${DOJO_ARTICLES_PREFIX}/207027356-Cloud-Monitors`,
  },
  {
    labelToken: localizationKey("Run Ad Hoc Network Discovery"),
    link: `${DOJO_ARTICLES_PREFIX}/207021723`,
  },
  {
    labelToken: localizationKey("How to mass-deploy the NinjaOne agent"),
    link: `${DOJO_ARTICLES_PREFIX}/207077733`,
  },
]

export const addTechniciansAndEndUsers = () => [
  {
    LeftIcon: PlayIconLight,
    titleToken: localizationKey("Watch Technician video (2 min)"),
    onClick: () =>
      showGettingStartedVideoModal({
        modalTitleToken: localizationKey("Technician"),
        modalDescriptionRenderer: () => (
          <Link href={`${DOJO_ARTICLES_PREFIX}/360003242791`}>
            {localized("Read full documentation about Technician")} <ExternalLinkIcon size="sm" />
          </Link>
        ),
        video: technicianVideo,
      }),
  },
  {
    LeftIcon: PlayIconLight,
    titleToken: localizationKey("Watch End-user video (4 min)"),
    onClick: () =>
      showGettingStartedVideoModal({
        modalTitleToken: localizationKey("End-user"),
        modalDescriptionRenderer: () => (
          <Link href={`${DOJO_ARTICLES_PREFIX}/360035894551`}>
            {localized("Read full documentation about End-user")} <ExternalLinkIcon size="sm" />
          </Link>
        ),
        video: endUserVideo,
      }),
  },
  {
    LeftIcon: PlusIcon,
    titleToken: localizationKey("Add user"),
    onClick: () => window.store.dispatch(openQuickMenu("add")),
    isAllowedUserForResource: user("canCreateUsers") || user("canCRUDEndUserSharing"),
  },
  {
    LeftIcon: ListIconLight,
    titleToken: localizationKey("Go to Accounts"),
    onClick: () => (window.location.hash = "#/administration/users/technicians"),
  },
]

export const addTechniciansAndEndUsersMoreArticles = [
  {
    titleToken: localizationKey("Related Articles:"),
    items: [
      {
        labelToken: localizationKey("Create a Technician"),
        link: `${DOJO_ARTICLES_PREFIX}/360003242791`,
      },
      {
        labelToken: localizationKey("End-user Sharing"),
        link: `${DOJO_ARTICLES_PREFIX}/360035894551`,
      },
      {
        labelToken: localizationKey("Access Ninja via Single Sign-on (SSO)"),
        link: `${DOJO_ARTICLES_PREFIX}/360059862912`,
      },
      {
        labelToken: localizationKey("Manage access and restrictions to features via Roles"),
        link: `${DOJO_ARTICLES_PREFIX}/5568986625165`,
      },
    ],
  },
]

export const manageOrganizations = [
  {
    LeftIcon: MemoIconLight,
    titleToken: localizationKey("Read documentation"),
    RightIcon: ExternalLinkIcon,
    onClick: () => {},
    dojoUrl: `${DOJO_ARTICLES_PREFIX}/360051694271`,
  },
  {
    LeftIcon: PlayIconLight,
    titleToken: localizationKey("Watch Organizations video (3 min)"),
    onClick: () =>
      showGettingStartedVideoModal({
        modalTitleToken: localizationKey("Organizations"),
        modalDescriptionRenderer: () => (
          <Link href={`${DOJO_ARTICLES_PREFIX}/360051694271`}>
            {localized("Read full documentation about Organizations")} <ExternalLinkIcon size="sm" />
          </Link>
        ),
        video: createOrgVideo,
      }),
  },
  {
    LeftIcon: ListIconLight,
    titleToken: localizationKey("Go to Organizations"),
    onClick: () => (window.location.hash = "#/administration/customers/list"),
  },
]
