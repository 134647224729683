import styled from "@emotion/styled"
import tokens from "@ninjaone/tokens"

const DashboardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-flow: row dense;
  gap: ${tokens.grid.gap};
  max-width: 2500px;
`
export default DashboardGrid
