import React, { PureComponent } from "react"
import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import { localized, debugLog, ninjaReportError } from "js/includes/common/utils"
import { colors } from "js/includes/common/theme"
import ProgressBar from "js/includes/components/ProgressBar"
import { getReadableBytes } from "js/includes/common/_conversions"
import { replaceSeparatorAndRemoveTrailingSlash } from "js/includes/components/Browsers"
import Spinner from "js/includes/components/Spinner"

export default class RestoreJobStatus extends PureComponent {
  state = {
    status: this.props.job.jobStatus,
    statusType: "COMMON_RESTORE_PROGRESS",
    file: this.props.job.content.file,
    fileTotalBytes: parseInt(this.props.job.content.fileTotalBytes, 10) || 0,
    fileDownloadedBytes: parseInt(this.props.job.content.fileDownloadedBytes, 10) || 0,
    jobFilesDownloaded: parseInt(this.props.job.content.jobFilesDownloaded, 10) || 0,
    jobTotalFiles: parseInt(this.props.job.content.jobTotalFiles, 10) || 0,
    jobBytesDownloaded: parseInt(this.props.job.content.jobBytesDownloaded, 10) || 0,
    jobTotalBytes: parseInt(this.props.job.content.jobTotalBytes, 10) || 0,
    percentage: parseInt(this.props.job.content.percentage, 10) || 0,
  }

  subscribeToJobsChannel = async () => {
    const {
      job: { jobUid, uid },
      node,
      setError,
      setCompleted,
    } = this.props

    try {
      const agentId = window.wamp._getAgentId(node.id)
      const jobUID = jobUid || uid

      this.subscription = await window.wamp.session.subscribe(`${agentId}.live.data.jobs`, jobs => {
        const [job] = jobs

        debugLog("wamp restore job status payload ", job)
        debugLog(job.jobUID, "\n", jobUID)

        if (this._isMounted && job.jobUID === jobUID) {
          if (
            job.statusType === "COMMON_RESTORE_PROGRESS" ||
            (job.statusType === "COMMON_RESTORE_EVENT" && job.status === "COMPLETED")
          ) {
            this.setState(
              {
                ...job,
                fileTotalBytes: parseInt(job.fileTotalBytes, 10),
                fileDownloadedBytes: parseInt(job.fileDownloadedBytes, 10),
                jobBytesDownloaded: parseInt(job.jobBytesDownloaded, 10),
                jobTotalBytes: parseInt(job.jobTotalBytes, 10),
                jobFilesDownloaded: parseInt(job.jobFilesDownloaded, 10),
                jobTotalFiles: parseInt(job.jobTotalFiles, 10),
                percentage: parseInt(job.percentage, 10),
              },
              () => {
                if (job.status === "COMPLETED") {
                  setCompleted(true)
                }
              },
            )
          }
          if (
            this.state.status !== "COMPLETED" &&
            job.status === "COMPLETED" &&
            job.statusType === "COMMON_RESTORE_EVENT"
          ) {
            this.setState({ status: "COMPLETED" }, () => {
              setCompleted(true)
            })
          }
        }
      })
    } catch (error) {
      debugLog(error)
      ninjaReportError(error)
      setError("continueWampLiveProcedure")
    }
  }

  async componentDidMount() {
    this._isMounted = true
    this.subscribeToJobsChannel()
  }

  componentWillUnmount() {
    this._isMounted = false

    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  render() {
    const {
      job: { content },
      node,
      error,
    } = this.props
    const {
      status,
      jobFilesDownloaded,
      jobTotalFiles,
      jobBytesDownloaded,
      jobTotalBytes,
      percentage: totalCompletionPercentage,
    } = this.state
    const separator = node.nodeClass === "MAC" ? "/" : "\\"
    const destinationDir = content.destinationPath
      ? replaceSeparatorAndRemoveTrailingSlash(content.destinationPath, separator)
      : ""
    const restoreToNodeName = node.displayName || node.friendlyName || node.name || node.systemName || ""

    // TODO: Dynamic Token
    return (
      <>
        <FormGroup className="text-left " controlId="restore-job-details-from">
          <Col componentClass={ControlLabel} xs={12}>
            {localized("Device")}
          </Col>
          <Col xs={12}>
            <div>{content.sourceNodeName}</div>
          </Col>
        </FormGroup>

        <FormGroup className="text-left " controlId="restore-job-details-from">
          <Col componentClass={ControlLabel} xs={12}>
            {localized("Plan Name")}
          </Col>
          <Col xs={12}>
            <div>{content.sourcePlanName}</div>
          </Col>
        </FormGroup>

        <FormGroup className="text-left " controlId="restore-job-details-from">
          <Col componentClass={ControlLabel} xs={12}>
            {localized("Path")}
          </Col>
          <Col xs={12}>
            <div className="break-all">{content.sourcePath || content.file}</div>
          </Col>
        </FormGroup>

        <FormGroup className="text-left" controlId="restore-job-details-to">
          <Col componentClass={ControlLabel} xs={12}>
            {localized("Restore To")}
          </Col>
          <Col xs={12}>
            <div>{restoreToNodeName}</div>
            <div className="word-break-all">{destinationDir}</div>
          </Col>
        </FormGroup>

        {jobTotalFiles === 1 && (
          <FormGroup className="text-left" controlId="restore-job-details-progress">
            <Col xs={12}>
              <div className="display-flex justify-content-between full-width">
                <span>
                  {totalCompletionPercentage}% {localized("Complete")}
                </span>
                <span>
                  {`${getReadableBytes(jobBytesDownloaded)} ${localized("of")} ${getReadableBytes(jobTotalBytes)}`}
                </span>
              </div>
              <ProgressBar progress={totalCompletionPercentage} borderColor={colors.ninjaBlueSaturated} />
            </Col>
          </FormGroup>
        )}

        {jobTotalFiles > 1 && (
          <>
            <FormGroup className="text-left" controlId="restore-job-details-progress">
              <Col xs={12}>
                <div className="display-flex justify-content-between full-width">
                  <span>
                    {totalCompletionPercentage}% {localized("Complete")}
                  </span>
                  <span>
                    {getReadableBytes(jobBytesDownloaded)} {localized("of")} {getReadableBytes(jobTotalBytes)}
                  </span>
                </div>
                <ProgressBar progress={totalCompletionPercentage} borderColor={colors.ninjaBlueSaturated} />
                <div className="text-ellipsis text-align-right">
                  <small>
                    {localized("{{num}} of {{total}} files", {
                      num: jobFilesDownloaded,
                      total: jobTotalFiles,
                    })}
                  </small>
                </div>
              </Col>
            </FormGroup>

            {status === "COMPLETED" && jobFilesDownloaded !== jobTotalFiles && (
              <div className="text-align-left m-l text-warning">
                {localized("Restored {{jobFilesDownloaded}} of {{jobTotalFiles}} successfully", {
                  jobFilesDownloaded,
                  jobTotalFiles,
                })}
              </div>
            )}
          </>
        )}

        {(!jobTotalFiles || !!error) && (
          <FormGroup className="text-left" controlId="restore-job-details-progress">
            <Col xs={12}>
              <div className="text-left">
                {error ? (
                  <span className="m-r-sm text-danger">
                    {localized(`configuration.integrations.lockhart.${error}`)}
                  </span>
                ) : (
                  <>
                    <span className="m-r-sm">{localized("Preparing restore job")}</span>
                    <Spinner />
                  </>
                )}
              </div>
            </Col>
          </FormGroup>
        )}
      </>
    )
  }
}
