import React from "react"
import { EmptyTableIcon } from "@ninjaone/icons"
import Text from "@ninjaone/components/src/Text"
import { Body } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { StyledEmptyTable } from "@ninjaone/components/src/Table"
import { localized } from "js/includes/common/utils/ssrAndWebUtils"
import { sortBy as _sortBy, length, compose, when, equals, reverse, is, toLower, prop } from "ramda"
import { SortDirection } from "react-virtualized"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner, faCaretDown, faCaretUp } from "@fortawesome/pro-solid-svg-icons"
import { Flex } from "js/includes/components/Styled"

export const noRowsRenderer = (
  token,
  isLoading = false,
  wrapperClassName = "col-xs-12",
  withEmptyBoxIcon = false,
) => () => (
  <div
    className="list-group-item horizontal-tile flex-tile no-hover no-padding no-borders"
    onClick={event => event.stopPropagation()}
  >
    <div className={wrapperClassName}>
      <hgroup style={{ marginLeft: 1 }}>
        {isLoading ? (
          <h4>
            <Flex alignItems="center" gap={tokens.spacing[2]} id="loading-container">
              <FontAwesomeIcon icon={faSpinner} spin />
              <Body color="colorTextWeak" fontWeight={tokens.typography.fontWeight.semiBold}>
                {localized("Loading")}...
              </Body>
            </Flex>
          </h4>
        ) : withEmptyBoxIcon ? (
          <StyledEmptyTable>
            <EmptyTableIcon />
            <Text size="sm" {...{ token }} />
          </StyledEmptyTable>
        ) : (
          <h4>
            <Body color="colorTextWeak" fontWeight={tokens.typography.fontWeight.semiBold}>
              {localized(token)}
            </Body>
          </h4>
        )}
      </hgroup>
    </div>
  </div>
)

// used with Table sorting
export const headerRenderer = ({ dataKey, label, sortBy, sortDirection }) => (
  <span className="ReactVirtualized__Table__headerTruncatedText" title={typeof label === "string" ? label : ""}>
    {label}
    {sortBy === dataKey && (
      <span className="m-l-xs">
        <FontAwesomeIcon icon={sortDirection === SortDirection.DESC ? faCaretDown : faCaretUp} />
      </span>
    )}
  </span>
)

export const sortReactVirtualizedList = (list, sortBy, sortDirection) =>
  compose(
    when(_ => equals(sortBy, "updateTime"), reverse),
    when(_ => equals(sortDirection, SortDirection.ASC), reverse),
    _sortBy(
      compose(
        when(
          // devices array
          is(Object),
          length,
        ),
        when(is(String), toLower),
        prop(sortBy),
      ),
    ),
  )(list)

export type rowRendererParams = {
  key: string,
  index: number,
  style: Object,
  rowData: Object,
}
