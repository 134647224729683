import { Fragment } from "react"
import { connect } from "react-redux"
import { append, indexOf, join, remove, find, propEq, map, isEmpty, path, assoc, pathOr } from "ramda"
import styled from "@emotion/styled"

import tokens from "@ninjaone/tokens"
import { Select, Switch, TitleGroup, Stepper, Heading, SearchableSelect, AlertMessage } from "@ninjaone/components"
import { Label } from "@ninjaone/components/src/Form/Label"

import { updatePolicyItem as _updatePolicyItem } from "js/state/actions/policyEditor/editor"
import { Box, Flex, StyledTruncatedTextContainer } from "js/includes/components/Styled"

import { emailValidation, showErrorMessage } from "js/includes/common/utils"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import InheritableRowPolicyItem from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/common/InheritableRowPolicyItem"
import {
  EncryptionPolicy,
  KeyguardDisabledFeature,
  BatteryPluggedMode,
  DeveloperSettings,
  ShowWorkContactsInPersonalProfile,
  CrossProfileCopyPaste,
  CrossProfileDataSharing,
  SystemUpdateType,
  PlayStoreMode,
  MtePolicySettings,
  AssistContentPolicyType,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/android/enums"
import { defaultInheritance } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"
import FreezePeriod from "./android/security/FreezePeriod"
import CustomMessaging from "./android/security/CustomMessaging"

const { spacing } = tokens

export const encryptionPolicyOptions = () => [
  { value: EncryptionPolicy.ENCRYPTION_POLICY_UNSPECIFIED, labelText: localized("Unspecified") },
  { value: EncryptionPolicy.ENABLED_WITHOUT_PASSWORD, labelText: localized("Enabled without Password") },
  { value: EncryptionPolicy.ENABLED_WITH_PASSWORD, labelText: localized("Enabled with Password") },
]

const keyguardDisabledFeaturesOptions = [
  // TODO: NJ-72878 KEYGUARD_DISABLED_FEATURE_UNSPECIFIED should not be given as options and instead be set when the selection is empty
  {
    value: KeyguardDisabledFeature.KEYGUARD_DISABLED_FEATURE_UNSPECIFIED,
    labelToken: localizationKey("This value is ignored"),
  },
  {
    value: KeyguardDisabledFeature.CAMERA,
    labelToken: localizationKey("Disable the camera on secure keyguard screens (e.g. PIN)"),
  },
  {
    value: KeyguardDisabledFeature.NOTIFICATIONS,
    labelToken: localizationKey("Disable showing all notifications on secure keyguard screens"),
  },
  {
    value: KeyguardDisabledFeature.UNREDACTED_NOTIFICATIONS,
    labelToken: localizationKey("Disable unredacted notifications on secure keyguard screens"),
  },
  {
    value: KeyguardDisabledFeature.TRUST_AGENTS,
    labelToken: localizationKey("Ignore trust agent state on secure keyguard screens"),
  },
  {
    value: KeyguardDisabledFeature.DISABLE_FINGERPRINT,
    labelToken: localizationKey("Disable fingerprint sensor on secure keyguard screens"),
  },
  {
    value: KeyguardDisabledFeature.DISABLE_REMOTE_INPUT,
    labelToken: localizationKey("Disable text entry into notifications on secure keyguard screens"),
  },
  {
    value: KeyguardDisabledFeature.FACE,
    labelToken: localizationKey("Disable face authentication on secure keyguard screens"),
  },
  {
    value: KeyguardDisabledFeature.IRIS,
    labelToken: localizationKey("Disable iris authentication on secure keyguard screens"),
  },
  {
    value: KeyguardDisabledFeature.BIOMETRICS,
    labelToken: localizationKey("Disable all biometric authentication on secure keyguard screens"),
  },
  {
    value: KeyguardDisabledFeature.ALL_FEATURES,
    labelToken: localizationKey("Disable all current and future keyguard customizations"),
  },
]

const stayOnPluggedModesOptions = [
  // TODO: NJ-72878 BATTERY_PLUGGED_MODE_UNSPECIFIED should not be given as options and instead be set when the selection is empty
  { value: BatteryPluggedMode.BATTERY_PLUGGED_MODE_UNSPECIFIED, labelToken: localizationKey("Unspecified") },
  { value: BatteryPluggedMode.AC, labelToken: localizationKey("AC") },
  { value: BatteryPluggedMode.USB, labelText: "USB" },
  { value: BatteryPluggedMode.WIRELESS, labelToken: localizationKey("Wireless") },
]

const developerSettingsOptions = () => [
  { value: DeveloperSettings.DEVELOPER_SETTINGS_UNSPECIFIED, labelText: localized("Unspecified") },
  { value: DeveloperSettings.DEVELOPER_SETTINGS_DISABLED, labelText: localized("Disabled") },
  { value: DeveloperSettings.DEVELOPER_SETTINGS_ALLOWED, labelText: localized("Allowed") },
]

const mtePolicySettingsOptions = () => [
  { value: MtePolicySettings.MTE_POLICY_UNSPECIFIED, labelText: localized("Unspecified") },
  { value: MtePolicySettings.MTE_USER_CHOICE, labelText: localized("User choice") },
  { value: MtePolicySettings.MTE_ENFORCED, labelText: localized("Enforced") },
  { value: MtePolicySettings.MTE_DISABLED, labelText: localized("Disabled") },
]

const showWorkContactsInPersonalProfileOptions = () => [
  {
    value: ShowWorkContactsInPersonalProfile.SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_UNSPECIFIED,
    labelText: localized("Unspecified"),
  },
  {
    value: ShowWorkContactsInPersonalProfile.SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_DISALLOWED,
    labelText: localized("Disallowed"),
  },
  {
    value: ShowWorkContactsInPersonalProfile.SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_ALLOWED,
    labelText: localized("Allowed"),
  },
  // TODO: NJ-72879 Support new SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_DISALLOWED_EXCEPT_SYSTEM option
]

const crossProfileCopyPasteOptions = () => [
  { value: CrossProfileCopyPaste.CROSS_PROFILE_COPY_PASTE_UNSPECIFIED, labelText: localized("Unspecified") },
  { value: CrossProfileCopyPaste.COPY_FROM_WORK_TO_PERSONAL_DISALLOWED, labelText: localized("Disallowed") },
  { value: CrossProfileCopyPaste.CROSS_PROFILE_COPY_PASTE_ALLOWED, labelText: localized("Allowed") },
]

const crossProfileDataSharingOptions = () => [
  { value: CrossProfileDataSharing.CROSS_PROFILE_DATA_SHARING_UNSPECIFIED, labelText: localized("Unspecified") },
  { value: CrossProfileDataSharing.CROSS_PROFILE_DATA_SHARING_DISALLOWED, labelText: localized("Disallowed") },
  {
    value: CrossProfileDataSharing.DATA_SHARING_FROM_WORK_TO_PERSONAL_DISALLOWED,
    labelText: localized("From Work to Personal Disallowed"),
  },
  { value: CrossProfileDataSharing.CROSS_PROFILE_DATA_SHARING_ALLOWED, labelText: localized("Allowed") },
]

const systemUpdateConfigTypeOptions = () => [
  { value: SystemUpdateType.SYSTEM_UPDATE_TYPE_UNSPECIFIED, labelText: localized("Unspecified") },
  { value: SystemUpdateType.AUTOMATIC, labelText: localized("Automatic") },
  { value: SystemUpdateType.WINDOWED, labelText: localized("Windowed") },
  { value: SystemUpdateType.POSTPONE, labelText: localized("Postpone") },
]

const assistContentPolicyOptions = [
  {
    value: AssistContentPolicyType.ASSIST_CONTENT_POLICY_UNSPECIFIED,
    labelText: localized("Unspecified"),
  },
  {
    value: AssistContentPolicyType.ASSIST_CONTENT_ALLOWED,
    labelText: localized("Allowed"),
  },
  {
    value: AssistContentPolicyType.ASSIST_CONTENT_DISALLOWED,
    labelText: localized("Blocked"),
  },
]

const getSecurityFormLocalizedLabels = () => ({
  MAX_TIME_TO_LOCK: localized("Maximum time to lock (seconds)"),
  ENCRYPTION_POLICY: localized("Encrption policy"),
  DISABLED_KEYGUARD_CUSTOMIZATION: localized("Disabled keyguard customizations"),
  BATTERY_PLUGGUD_MODE: localized("Battery plugged mode"),
  DEVELOPER_SETTINGS: localized("Developer settings"),
  FRP_ALLOWLISTED_ACCOUNTS: localized("Factory reset protection allowlisted accounts"),
  MAX_DAYS_WITH_WORK_OFF: localized("Controls how long the work profile can stay off (days)"),
  SHOW_WORK_CONTACTS_IN_PERSONAL: localized("Shown in personal profile contact searches and incoming calls"),
  COPY_AND_PASTE_BETWEEN_PROFILES: localized("Copy and paste between profiles"),
  CROSS_PROFILE_DATA_SHARING: localized("Data from either profile can be shared with the other profile"),
  SYSTEM_UPDATE_TYPE: localized("Type"),
  START_MINUTES: localized("Start minutes"),
  END_MINUTES: localized("End minutes"),
  SHORT_SUPPORT_MESSAGE: localized("Short support message"),
  LONG_SUPPORT_MESSAGE: localized("Long support message"),
  LOCK_SCREEN_MESSAGE: localized("Lock screen message"),

  SHOW_WORK_CONTACTS_ARIA_LABEL: localized("Show work contacts in personal profile"),
  CROSS_PROFILE_ARIA_LABEL: localized("Cross profile data sharing"),
  SYSTEM_UPDATE_TYPE_ARIA_LABEL: localized("System update config type"),

  MEMORY_TAGGING_EXTENSION: localized("Memory Tagging Extension")
})

// TODO: Centralize GroupHeading and FieldRow to own components

const StyledGroupHeading = styled(Box)`
  /* 
    Styles when there's inheritance in the heading 
    InheritableRowPolicyItem already provides padding and border
  */
  ${({ noPadding }) => !noPadding && `padding-bottom: ${spacing[2]}`};
  ${({ noBorder, theme }) => !noBorder && `border-bottom: 1px solid ${theme.colorBorderWeakest}`};
`

export const GroupHeading = ({ textToken, inheritance = null, headingLevel = 3 }) => {
  const Wrapper = !!inheritance ? InheritableRowPolicyItem : Fragment

  return (
    <Box marginTop={spacing[4]} data-test-name="GroupHeading">
      <Wrapper {...(!!inheritance && { ...inheritance, wrapperProps: { padding: `${spacing[2]}` } })}>
        <StyledGroupHeading {...(!!inheritance && { noBorder: true, noPadding: true })}>
          <Heading level={headingLevel} type="headingM" color="colorTextStrong" children={localized(textToken)} />
        </StyledGroupHeading>
      </Wrapper>
    </Box>
  )
}

const StyledFieldRow = styled(Flex)`
  &:hover {
    background-color: ${({ theme }) => theme.color.black["005"]};
  }
  /*
    Extra padding so FieldRows without inheritance have the same styling as the ones that do
  */
  ${({ addPadding }) => addPadding && `padding: ${spacing[5]} ${spacing[2]};`}
`

const StyledMessagingRow = styled(Flex)`
  justify-content: space-between;
  align-items: flex-start;
  padding-top: ${spacing[3]};
  gap: ${spacing[8]};
  border-bottom: ${({ theme }) => `1px solid ${theme.colorBorderWeakest}`};
`

export const FieldRow = ({ children, inheritance = null }) => {
  const Wrapper = !!inheritance ? InheritableRowPolicyItem : Fragment

  return (
    <StyledFieldRow {...{ addPadding: !inheritance }} data-test-name="FieldRow">
      <Wrapper {...(!!inheritance && { ...inheritance, noBorder: true })}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          maxWidth={!!inheritance ? "100%" : "calc(100% - 200px)"}
        >
          {children}
        </Flex>
      </Wrapper>
    </StyledFieldRow>
  )
}

const root = "security"

// TODO : Move keys and default values to util once NJ-86281 is merged

const keys = {
  lockSettings: "lockSettings",
  maximumTimeToLock: "maximumTimeToLock",

  encryptionSettings: "encryptionSettings",
  encryptionPolicy: "encryptionPolicy",

  keyguardSettings: "keyguardSettings",
  keyguardDisabledFeatures: "keyguardDisabledFeatures",

  batterySettings: "batterySettings",
  stayOnPluggedModes: "stayOnPluggedModes",

  developerSettings: "developerSettings",

  mtePolicySettings: "mtePolicySettings",
  mtePolicy: "mtePolicy",
  assistContentPolicySettings: "assistContentPolicySettings",
  assistContentPolicy: "assistContentPolicy",

  credentialsConfigDisabledSettings: "credentialsConfigDisabledSettings",
  credentialsConfigDisabled: "credentialsConfigDisabled",

  personalUsageSettings: "personalUsageSettings",
  cameraDisabled: "cameraDisabled",
  screenCaptureDisabled: "screenCaptureDisabled",
  maxDaysWithWorkOff: "maxDaysWithWorkOff",
  personalPlayStoreMode: "personalPlayStoreMode",

  crossProfileSettings: "crossProfileSettings",
  showWorkContactsInPersonalProfile: "showWorkContactsInPersonalProfile",
  crossProfileCopyPaste: "crossProfileCopyPaste",
  crossProfileDataSharing: "crossProfileDataSharing",

  systemUpdateSettings: "systemUpdateSettings",
  updateTypeSettings: "updateTypeSettings",
  type: "type",
  startMinutes: "startMinutes",
  endMinutes: "endMinutes",
  freezePeriodsSettings: "freezePeriodsSettings",
  freezePeriods: "freezePeriods",

  minimumApiLevelSettings: "minimumApiLevelSettings",
  minimumApiLevel: "minimumApiLevel",
  frpAdminEmailSettings: "frpAdminEmailSettings",
  frpAdminEmails: "frpAdminEmails",

  deviceOwnerLockScreenInfo: "deviceOwnerLockScreenInfo",
  shortSupportMessage: "shortSupportMessage",
  longSupportMessage: "longSupportMessage",
  defaultMessage: "defaultMessage",
  message: "message",
  active: "active",
  localizedMessages: "localizedMessages",
}

export const defaultValues = {
  [keys.lockSettings]: { [keys.maximumTimeToLock]: 0, ...defaultInheritance },
  [keys.encryptionSettings]: {
    [keys.encryptionPolicy]: EncryptionPolicy.ENCRYPTION_POLICY_UNSPECIFIED,
    ...defaultInheritance,
  },
  [keys.keyguardSettings]: {
    [keys.keyguardDisabledFeatures]: [],
    ...defaultInheritance,
  },
  [keys.batterySettings]: {
    [keys.stayOnPluggedModes]: [],
    ...defaultInheritance,
  },
  [keys.developerSettings]: {
    [keys.developerSettings]: DeveloperSettings.DEVELOPER_SETTINGS_UNSPECIFIED,
    ...defaultInheritance,
  },
  [keys.mtePolicySettings]: {
    [keys.mtePolicy]: MtePolicySettings.MTE_POLICY_UNSPECIFIED,
    ...defaultInheritance,
  },
  [keys.assistContentPolicySettings]: {
    [keys.assistContentPolicy]: AssistContentPolicyType.ASSIST_CONTENT_POLICY_UNSPECIFIED,
    ...defaultInheritance,
  },
  [keys.credentialsConfigDisabledSettings]: {
    [keys.credentialsConfigDisabled]: false,
    ...defaultInheritance,
  },
  [keys.personalUsageSettings]: {
    [keys.cameraDisabled]: false,
    [keys.screenCaptureDisabled]: false,
    [keys.maxDaysWithWorkOff]: 0,
    [keys.personalPlayStoreMode]: PlayStoreMode.PLAY_STORE_MODE_UNSPECIFIED,
    ...defaultInheritance,
  },
  [keys.crossProfileSettings]: {
    [keys.showWorkContactsInPersonalProfile]:
      ShowWorkContactsInPersonalProfile.SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_UNSPECIFIED,
    [keys.crossProfileCopyPaste]: CrossProfileCopyPaste.CROSS_PROFILE_COPY_PASTE_UNSPECIFIED,
    [keys.crossProfileDataSharing]: CrossProfileDataSharing.CROSS_PROFILE_DATA_SHARING_UNSPECIFIED,
    ...defaultInheritance,
  },
  [keys.systemUpdateSettings]: {
    [keys.updateTypeSettings]: {
      type: SystemUpdateType.SYSTEM_UPDATE_TYPE_UNSPECIFIED,
      ...defaultInheritance,
    },
    [keys.freezePeriodsSettings]: {
      freezePeriods: [],
      ...defaultInheritance,
    },
  },
  [keys.minimumApiLevelSettings]: {
    [keys.minimumApiLevel]: null,
    ...defaultInheritance,
  },
  [keys.frpAdminEmailSettings]: {
    [keys.frpAdminEmails]: [],
    ...defaultInheritance,
  },
  [keys.deviceOwnerLockScreenInfo]: {
    [keys.defaultMessage]: {
      [keys.message]: "",
      [keys.active]: false,
      ...defaultInheritance,
    },
    [keys.localizedMessages]: {},
  },
  [keys.shortSupportMessage]: {
    [keys.defaultMessage]: {
      [keys.message]: "",
      [keys.active]: false,
      ...defaultInheritance,
    },
    [keys.localizedMessages]: {},
  },
  [keys.longSupportMessage]: {
    [keys.defaultMessage]: {
      [keys.message]: "",
      [keys.active]: false,
      ...defaultInheritance,
    },
    [keys.localizedMessages]: {},
  },
}

const mapFrpEmailsToOptions = adminEmails => adminEmails.map(email => ({ value: email, labelText: email }))

const SecurityForm = ({ securityPolicy, updatePolicyItem, parentPolicy }) => {
  const {
    lockSettings: { maximumTimeToLock = defaultValues.lockSettings.maximumTimeToLock } = {},
    encryptionSettings: { encryptionPolicy = defaultValues.encryptionSettings.encryptionPolicy } = {},
    keyguardSettings: { keyguardDisabledFeatures = defaultValues.keyguardSettings.keyguardDisabledFeatures } = {},
    batterySettings: { stayOnPluggedModes = defaultValues.batterySettings.stayOnPluggedModes } = {},
    developerSettings: { developerSettings = defaultValues.developerSettings.developerSettings } = {},
    mtePolicySettings: { mtePolicy = defaultValues.mtePolicySettings.mtePolicy } = {},
    credentialsConfigDisabledSettings: {
      credentialsConfigDisabled = defaultValues.credentialsConfigDisabledSettings.credentialsConfigDisabled,
    } = {},
    assistContentPolicySettings: {
      assistContentPolicy = defaultValues.assistContentPolicySettings.assistContentPolicy,
    } = {},
    personalUsageSettings: {
      cameraDisabled = defaultValues.personalUsageSettings.cameraDisabled,
      screenCaptureDisabled = defaultValues.personalUsageSettings.screenCaptureDisabled,
      maxDaysWithWorkOff = defaultValues.personalUsageSettings.maxDaysWithWorkOff,
    } = {},
    crossProfileSettings: {
      showWorkContactsInPersonalProfile = defaultValues.crossProfileSettings.showWorkContactsInPersonalProfile,
      crossProfileCopyPaste = defaultValues.crossProfileSettings.crossProfileCopyPaste,
      crossProfileDataSharing = defaultValues.crossProfileSettings.crossProfileDataSharing,
    } = {},
    frpAdminEmailSettings: { frpAdminEmails = defaultValues.frpAdminEmailSettings.frpAdminEmails } = {},
    systemUpdateSettings: {
      updateTypeSettings: {
        type: systemUpdateType = defaultValues.systemUpdateSettings.updateTypeSettings.type,
        startMinutes: systemUpdateStartMinutes = 0,
        endMinutes: systemUpdateEndMinutes = 0,
      } = defaultValues.systemUpdateSettings.updateTypeSettings,
      freezePeriodsSettings: {
        freezePeriods = defaultValues.systemUpdateSettings.freezePeriodsSettings.freezePeriods,
      } = defaultValues.systemUpdateSettings.freezePeriodsSettings,
    } = {},
    minimumApiLevelSettings: { minimumApiLevel = defaultValues.minimumApiLevelSettings.minimumApiLevel } = {},
    shortSupportMessage = defaultValues.shortSupportMessage,
    longSupportMessage = defaultValues.longSupportMessage,
    deviceOwnerLockScreenInfo = defaultValues.deviceOwnerLockScreenInfo,
  } = securityPolicy ?? {}

  const securityFormLabels = getSecurityFormLocalizedLabels()

  const frpEmailOptions = mapFrpEmailsToOptions(frpAdminEmails)

  const updateState = (category, key, value) => {
    const categoryPath = [...category.split(".")]
    const updatedPath = [categoryPath, key]
    const currentValue = path(updatedPath, securityPolicy)

    if (value === currentValue) return

    const defaultValue = path(categoryPath, defaultValues)
    const currentCategory = pathOr(defaultValue, categoryPath, securityPolicy)
    const updatedSecurity = assoc(key, value, currentCategory)

    // Handle side-effects of system update type change
    if (category.includes(keys.updateTypeSettings) && key === keys.type) {
      // Remove start/end minutes if not set to windowed
      if (updatedSecurity[keys.type] !== SystemUpdateType.WINDOWED) {
        delete updatedSecurity[keys.startMinutes]
        delete updatedSecurity[keys.endMinutes]
      }
    }

    // Add default personal Play Store mode on policy if not there already
    if (securityPolicy?.personalUsageSettings?.personalPlayStoreMode !== PlayStoreMode.PLAY_STORE_MODE_UNSPECIFIED) {
      updatePolicyItem(`${root}.${keys.personalUsageSettings}`, parentPolicy, {
        ...defaultValues.personalUsageSettings,
        ...securityPolicy.personalUsageSettings,
      })
    }

    updatePolicyItem(`${root}.${category}`, parentPolicy, updatedSecurity)
  }

  const updateMultipleValue = (category, key, value, list) => {
    const valueExists = indexOf(value)(list)
    const newValuesList = valueExists >= 0 ? remove(valueExists, 1, list) : append(value, list)
    updateState(category, key, newValuesList)
  }

  const updateFreezePeriods = updatedFreezePeriods => {
    updateState(`${keys.systemUpdateSettings}.${keys.freezePeriodsSettings}`, keys.freezePeriods, updatedFreezePeriods)
  }

  const updateFrpAdminEmail = updatedEmails => {
    updateState(keys.frpAdminEmailSettings, keys.frpAdminEmails, updatedEmails)
  }

  return (
    <Box overflowY="auto" padding={[spacing[0], spacing[4], spacing[12], spacing[6]]}>
      <Box marginBottom={spacing[7]}>
        <TitleGroup
          TitleComponent={() => <Heading level={2} children={localized("Security")} type="headingL" />}
          descriptionToken={localizationKey("Manage security settings for this policy.")}
        />
      </Box>

      <GroupHeading textToken={localizationKey("General")} />

      <FieldRow
        inheritance={{
          pathToItem: `${root}.${keys.minimumApiLevelSettings}`,
          inheritableItem: securityPolicy?.minimumApiLevelSettings ?? defaultInheritance,
        }}
      >
        <Stepper
          compact
          min={0}
          labelText={localized("Minimum Android version support (API level)")}
          tooltipText={localized("The minimum allowed Android API level.")}
          value={minimumApiLevel}
          onChange={value => updateState(keys.minimumApiLevelSettings, keys.minimumApiLevel, value > 0 ? value : null)}
        />
      </FieldRow>

      <FieldRow
        inheritance={{
          pathToItem: `${root}.${keys.lockSettings}`,
          inheritableItem: securityPolicy?.lockSettings ?? defaultInheritance,
        }}
      >
        <Stepper
          compact
          min={0}
          max={1800}
          labelText={securityFormLabels.MAX_TIME_TO_LOCK}
          value={maximumTimeToLock}
          onChange={value => updateState(keys.lockSettings, keys.maximumTimeToLock, value)}
        />
      </FieldRow>

      <FieldRow
        inheritance={{
          pathToItem: `${root}.${keys.encryptionSettings}`,
          inheritableItem: securityPolicy?.encryptionSettings ?? defaultInheritance,
        }}
      >
        <Label labelFor={keys.encryptionPolicy} labelText={securityFormLabels.ENCRYPTION_POLICY} />
        <Box width="300px">
          <SearchableSelect
            ariaLabel={securityFormLabels.ENCRYPTION_POLICY}
            options={encryptionPolicyOptions()}
            value={encryptionPolicy}
            onChange={selected => updateState(keys.encryptionSettings, keys.encryptionPolicy, selected)}
            matchWidth
            popoverProps={{
              portal: false,
            }}
          />
        </Box>
      </FieldRow>

      <FieldRow
        inheritance={{
          pathToItem: `${root}.${keys.keyguardSettings}`,
          inheritableItem: securityPolicy?.keyguardSettings ?? defaultInheritance,
        }}
      >
        <Label labelFor={keys.keyguardDisabledFeatures} labelText={securityFormLabels.DISABLED_KEYGUARD_CUSTOMIZATION} />
        {/* TODO: replace with SearchableSelect when option for show groupped multiselection value labels will be available */}
        <Select
          labelId={keys.keyguardDisabledFeatures}
          triggerAriaLabel={securityFormLabels.DISABLED_KEYGUARD_CUSTOMIZATION}
          options={keyguardDisabledFeaturesOptions}
          value={keyguardDisabledFeatures}
          onChange={selected => {
            updateMultipleValue(
              keys.keyguardSettings,
              keys.keyguardDisabledFeatures,
              selected,
              keyguardDisabledFeatures,
            )
          }}
          labelRenderer={() => {
            const count = keyguardDisabledFeatures.length
            return (
              <StyledTruncatedTextContainer>
                {count === 0 ? localized("None") : localized("{{count}} option(s) selected", { count })}
              </StyledTruncatedTextContainer>
            )
          }}
        />
      </FieldRow>

      <FieldRow
        inheritance={{
          pathToItem: `${root}.${keys.batterySettings}`,
          inheritableItem: securityPolicy?.batterySettings ?? defaultInheritance,
        }}
      >
        <Label labelFor={keys.stayOnPluggedModes} labelText={securityFormLabels.BATTERY_PLUGGUD_MODE} />
        {/* TODO: replace with SearchableSelect when option for show groupped multiselection value labels will be available */}
        <Select
          labelId={keys.stayOnPluggedModes}
          triggerAriaLabel={securityFormLabels.BATTERY_PLUGGUD_MODE}
          options={stayOnPluggedModesOptions}
          value={stayOnPluggedModes}
          onChange={selected =>
            updateMultipleValue(keys.batterySettings, keys.stayOnPluggedModes, selected, stayOnPluggedModes)
          }
          labelRenderer={() => {
            const getBatteryModesLabels = selectedValues => {
              if (isEmpty(selectedValues)) return localized("None")

              return join(
                ", ",
                map(value => {
                  const { labelToken, labelText } = find(propEq("value", value), stayOnPluggedModesOptions) ?? {}
                  return labelToken ? localized(labelToken) : labelText
                }, selectedValues),
              )
            }

            return (
              <StyledTruncatedTextContainer>{getBatteryModesLabels(stayOnPluggedModes)}</StyledTruncatedTextContainer>
            )
          }}
        />
      </FieldRow>

      <FieldRow
        inheritance={{
          pathToItem: `${root}.${keys.developerSettings}`,
          inheritableItem: securityPolicy?.developerSettings ?? defaultInheritance,
        }}
      >
        <Label labelFor={keys.developerSettings} labelText={securityFormLabels.DEVELOPER_SETTINGS} />
        <Box width="300px">
          <SearchableSelect
            ariaLabel={securityFormLabels.DEVELOPER_SETTINGS}
            options={developerSettingsOptions()}
            value={developerSettings}
            onChange={selected => updateState(keys.developerSettings, keys.developerSettings, selected)}
          />
        </Box>
      </FieldRow>

      <FieldRow
        inheritance={{
          pathToItem: `${root}.${keys.mtePolicySettings}`,
          inheritableItem: securityPolicy?.mtePolicySettings ?? defaultInheritance,
        }}
      >
        <Label labelFor={keys.mtePolicySettings} labelText={securityFormLabels.MEMORY_TAGGING_EXTENSION} />
        <Box width="300px">
          <SearchableSelect
            ariaLabel={securityFormLabels.MEMORY_TAGGING_EXTENSION}
            options={mtePolicySettingsOptions()}
            value={mtePolicy}
            onChange={selected => updateState(keys.mtePolicySettings, keys.mtePolicy, selected)}
          />
        </Box>
      </FieldRow>

      <FieldRow
        inheritance={{
          pathToItem: `${root}.${keys.assistContentPolicySettings}`,
          inheritableItem: securityPolicy?.assistContentPolicySettings ?? defaultInheritance,
        }}
      >
        <Label
          labelFor={keys.assistContentPolicy}
          labelText={localized("Send content to assist apps")}
          tooltipText={localized(
            "Allows for contextual content about the current app or screen state to be provided to assist apps such as Gemini, supported on Android 15+, disable to prevent content from being shared.",
          )}
        />
        <Box width="300px">
          <SearchableSelect
            value={assistContentPolicy}
            options={assistContentPolicyOptions}
            onChange={selected => updateState(keys.assistContentPolicySettings, keys.assistContentPolicy, selected)}
            triggerAriaLabel={localized("Send content to assist apps")}
          />
        </Box>
      </FieldRow>

      <FieldRow
        inheritance={{
          pathToItem: `${root}.${keys.credentialsConfigDisabledSettings}`,
          inheritableItem: securityPolicy?.credentialsConfigDisabledSettings ?? defaultInheritance,
        }}
      >
        <Switch
          labelToken={localizationKey("User credentials configuration disabled")}
          checked={credentialsConfigDisabled}
          onChange={checked =>
            updateState(keys.credentialsConfigDisabledSettings, keys.credentialsConfigDisabled, checked)
          }
        />
      </FieldRow>

      <FieldRow
        inheritance={{
          pathToItem: `${root}.${keys.frpAdminEmailSettings}`,
          inheritableItem: securityPolicy?.frpAdminEmailSettings ?? defaultInheritance,
        }}
      >
        <Label
          id={keys.frpAdminEmails}
          forInputElement
          labelFor={keys.frpAdminEmails}
          labelText={securityFormLabels.FRP_ALLOWLISTED_ACCOUNTS}
        />
        <Box width="300px">
          <SearchableSelect
            ariaLabel={localized("Factory reset protection emails")}
            comboboxProps={{
              creatable: true,
              onCreate: newEmail => {
                if (!emailValidation(newEmail)) {
                  showErrorMessage(localized("Invalid email"))
                  return
                }
                updateState(keys.frpAdminEmailSettings, keys.frpAdminEmails, [...frpAdminEmails, newEmail])
              },
            }}
            isMulti
            value={frpAdminEmails}
            onChange={updateFrpAdminEmail}
            options={frpEmailOptions}
            placeholderText={localized("Enter an email")}
          />
        </Box>
      </FieldRow>

      <GroupHeading textToken={localizationKey("Custom messaging")} />

      <Box marginTop={spacing[4]} marginBottom={spacing[6]}>
        <AlertMessage>{localized("All default messages are optional, unless a language is added.")}</AlertMessage>

        {[
          {
            label: securityFormLabels.SHORT_SUPPORT_MESSAGE,
            tooltipText: localized("Displays when an action is not permitted"),
            entryKey: keys.shortSupportMessage,
            entryValue: shortSupportMessage,
          },
          {
            label: securityFormLabels.LONG_SUPPORT_MESSAGE,
            tooltipText: localized("Displays when a user taps for more information on the action disallowed prompt"),
            entryKey: keys.longSupportMessage,
            entryValue: longSupportMessage,
          },
          {
            label: securityFormLabels.LOCK_SCREEN_MESSAGE,
            tooltipText: localized(
              "Define a message that appears on the lockscreen of the device. Short and concise messages are recommended.",
            ),
            entryKey: keys.deviceOwnerLockScreenInfo,
            entryValue: deviceOwnerLockScreenInfo,
          },
        ].map(({ label, tooltipText, entryKey, entryValue }) => (
          <StyledMessagingRow key={entryKey} data-testid={entryKey}>
            <Box minWidth="200px" marginTop="90px">
              <Label labelFor={entryKey} labelText={label} tooltipText={tooltipText} />
            </Box>
            <CustomMessaging {...{ entryKey, entryValue, label, tooltipText }} withProvider={true} />
          </StyledMessagingRow>
        ))}
      </Box>

      <GroupHeading
        textToken={localizationKey("Personal Usage Policies")}
        inheritance={{
          pathToItem: `${root}.${keys.personalUsageSettings}`,
          inheritableItem: securityPolicy?.personalUsageSettings ?? defaultInheritance,
        }}
      />

      <FieldRow>
        <Switch
          labelToken={localizationKey("Camera disabled")}
          checked={cameraDisabled}
          onChange={checked => updateState(keys.personalUsageSettings, keys.cameraDisabled, checked)}
        />
      </FieldRow>

      <FieldRow>
        <Switch
          labelToken={localizationKey("Screen capture disabled")}
          checked={screenCaptureDisabled}
          onChange={checked => updateState(keys.personalUsageSettings, keys.screenCaptureDisabled, checked)}
        />
      </FieldRow>

      <FieldRow>
        <Stepper
          compact
          labelText={securityFormLabels.MAX_DAYS_WITH_WORK_OFF}
          min={0}
          max={400}
          value={maxDaysWithWorkOff}
          onChange={value => updateState(keys.personalUsageSettings, keys.maxDaysWithWorkOff, value)}
        />
      </FieldRow>

      <GroupHeading
        textToken={localizationKey("Cross-profile policies applied on the device")}
        inheritance={{
          pathToItem: `${root}.${keys.crossProfileSettings}`,
          inheritableItem: securityPolicy?.crossProfileSettings ?? defaultInheritance,
        }}
      />

      <FieldRow>
        <Label
          labelFor={keys.showWorkContactsInPersonalProfile}
          labelText={securityFormLabels.SHOW_WORK_CONTACTS_IN_PERSONAL}
        />
        <Box width="300px">
          <SearchableSelect
            ariaLabel={keys.showWorkContactsInPersonalProfile}
            options={showWorkContactsInPersonalProfileOptions()}
            value={showWorkContactsInPersonalProfile}
            onChange={selected =>
              updateState(keys.crossProfileSettings, keys.showWorkContactsInPersonalProfile, selected)
            }
            alignRight={false}
          />
        </Box>
      </FieldRow>

      <FieldRow>
        <Label labelFor={keys.crossProfileCopyPaste} labelText={securityFormLabels.COPY_AND_PASTE_BETWEEN_PROFILES} />
        <Box width="300px">
          <SearchableSelect
            ariaLabel={securityFormLabels.COPY_AND_PASTE_BETWEEN_PROFILES}
            options={crossProfileCopyPasteOptions()}
            value={crossProfileCopyPaste}
            onChange={selected => updateState(keys.crossProfileSettings, keys.crossProfileCopyPaste, selected)}
          />
        </Box>
      </FieldRow>

      <FieldRow>
        <Label
          labelFor={keys.crossProfileDataSharing}
          labelText={securityFormLabels.CROSS_PROFILE_DATA_SHARING}
        />
        <Box width="300px">
          <SearchableSelect
            ariaLabel={keys.crossProfileDataSharing}
            options={crossProfileDataSharingOptions()}
            value={crossProfileDataSharing}
            onChange={selected => updateState(keys.crossProfileSettings, keys.crossProfileDataSharing, selected)}
          />
        </Box>
      </FieldRow>

      <GroupHeading
        textToken={localizationKey("System Update Configuration")}
        inheritance={{
          pathToItem: `${root}.${keys.systemUpdateSettings}.${keys.updateTypeSettings}`,
          inheritableItem:
            securityPolicy?.systemUpdateSettings?.updateTypeSettings ??
            defaultValues.systemUpdateSettings.updateTypeSettings,
        }}
      />

      <FieldRow>
        <Label labelFor={keys.type} labelText={securityFormLabels.SYSTEM_UPDATE_TYPE} />
        <Box width="300px">
          <SearchableSelect
            ariaLabel={securityFormLabels.SYSTEM_UPDATE_TYPE_ARIA_LABEL}
            options={systemUpdateConfigTypeOptions()}
            value={systemUpdateType}
            onChange={selected =>
              updateState(`${keys.systemUpdateSettings}.${keys.updateTypeSettings}`, keys.type, selected)
            }
          />
        </Box>
      </FieldRow>

      {systemUpdateType === SystemUpdateType.WINDOWED && (
        <>
          <FieldRow>
            <Stepper
              compact
              labelText={securityFormLabels.START_MINUTES}
              min={0}
              max={1439}
              value={systemUpdateStartMinutes}
              onChange={value =>
                updateState(`${keys.systemUpdateSettings}.${keys.updateTypeSettings}`, keys.startMinutes, value)
              }
            />
          </FieldRow>

          <FieldRow>
            <Stepper
              compact
              labelText={securityFormLabels.END_MINUTES}
              min={0}
              max={1439}
              value={systemUpdateEndMinutes}
              onChange={value =>
                updateState(`${keys.systemUpdateSettings}.${keys.updateTypeSettings}`, keys.endMinutes, value)
              }
            />
          </FieldRow>
        </>
      )}

      <GroupHeading
        textToken={localizationKey("Freeze Periods")}
        inheritance={{
          pathToItem: `${root}.${keys.systemUpdateSettings}.${keys.freezePeriodsSettings}`,
          inheritableItem:
            securityPolicy?.systemUpdateSettings?.freezePeriodsSettings ??
            defaultValues.systemUpdateSettings.freezePeriodsSettings,
        }}
      />

      <FreezePeriod
        {...{
          freezePeriods,
          handleUpdate: updateFreezePeriods,
        }}
      />
    </Box>
  )
}

export default connect(
  ({ policyEditor }) => ({
    securityPolicy: policyEditor.policy.content.security,
    parentPolicy: policyEditor.parentPolicy,
  }),
  {
    updatePolicyItem: _updatePolicyItem,
  },
)(SecurityForm)
