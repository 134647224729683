export const units = {
  millis_in_day: 24 * 60 * 60 * 1000,
  millis_in_six_days: 6 * 24 * 60 * 60 * 1000,
  secs_in_day: 24 * 60 * 60,
  secs_in_hour: 60 * 60,
  secs_in_min: 60,
  bytes_in_gb: 1024 * 1024 * 1024,
  bytes_in_mb: 1024 * 1024,
  bytes_in_kb: 1024,
  gb_in_pb: 1050000,
  hz_in_ghz: 1000 * 1000 * 1000,
  hz_in_mhz: 1000 * 1000,
}

export const BULK_IMPORT_LIMIT = 5000

export const averageIdentifier = "_average_"

// Source: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
export const EMAIL_REGEX = /^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}$/i

export const MIN_INTEGER_VALUE = -2147483648 //Java Integer.MIN_VALUE
export const MAX_INTEGER_VALUE = 2147483647 //Java Integer.MAX_VALUE
export const MAX_FLOAT_VALUE = 3.4028234663852886e38 //Java Float.MAX_VALUE
export const MIN_FLOAT_VALUE = 1.4e-45 //Java Float.MIN_VALUE
export const MAX_WYSIWYG_HTML_LENGTH = 200000
export const MAX_WYSIWYG_HTML_LENGTH_FOR_AUTO_VIEW = 10000
export const WYSIWYG_MAX_ALLOWANCE = 20

//splashtop ask for confirmation settings
export const ASK_FOR_CONFIRMATION_DISABLED = 0
export const REJECT_CONNECTION = 1
export const APPROVE_CONNECTION = 2

// Ninja Remote Ask For Confirmation settings
export const ASK_FOR_CONFIRMATION_DEFAULT_TIME_DURATION_IN_SECONDS = 120
export const ASK_FOR_CONFIRMATION_WITHOUT_TIMEOUT = -1

export const MAX_WARRANTY_SCHEDULE_THRESHOLD_VALUE = 364
