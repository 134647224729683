import { useEffect } from "react"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { AlertMessage, Body, Heading } from "@ninjaone/components"
import { spacing } from "@ninjaone/tokens"
import { DOJO_ARTICLES_PREFIX, localizationKey, localized, localizedWith } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import LinksColumn from "js/includes/GetStartedPage/components/LinksColumn"
import VideoCard from "js/includes/GetStartedPage/components/VideoCard"
import { handleOpenGuideTooltip, closeGuideTooltip } from "js/state/actions/general/guideTooltips"
import Link from "js/includes/components/Link"

const StyledWrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 248px), 1fr));
  gap: ${spacing[3]};
  margin-top: ${spacing[3]};
`

const GettingStartedStepThree = ({
  activeStep,
  deviceVideos,
  externalLinks,
  onTaskCompleted,
  stepsCompleted,
  permissionRequired,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!stepsCompleted[activeStep]) dispatch(handleOpenGuideTooltip("addDeviceMenu"))
    onTaskCompleted(activeStep)
  }, [activeStep, dispatch, onTaskCompleted, stepsCompleted])

  useEffect(() => {
    return () => {
      dispatch(closeGuideTooltip("addDeviceMenu"))
    }
  }, [dispatch])

  const { name, allowed } = permissionRequired

  return (
    <>
      <Heading level={2} type="headingS">
        {localized("Add devices")}
      </Heading>

      <Body color="colorTextWeakest" textWrap as="span">
        {localizedWith(
          "Adding a device to NinjaOne gives you full visibility and control over that device for inventorying, management and support. <%link>See System Requirements and OS Support<%> before installing NinjaOne on a device.",
          {
            link: ({ localizedText }) => <Link href={`${DOJO_ARTICLES_PREFIX}/218365623`}>{localizedText}</Link>,
          },
        )}
      </Body>

      {!allowed && (
        <Box marginTop={spacing[4]}>
          <AlertMessage variant="warning">
            {localized(
              "You do not have permissions to manage {{permissionName}}. Please reach out to your System Admin for help.",
              { permissionName: name },
            )}
          </AlertMessage>
        </Box>
      )}

      <Box paddingTop={!allowed ? spacing[4] : spacing[6]}>
        <Body color="colorTextWeakest">
          {localized("Learn how to deploy NinjaOne to different operating systems:")}
        </Body>
      </Box>

      {deviceVideos?.length && (
        <StyledWrapper>
          {deviceVideos.map(({ id, ...restProps }) => (
            <VideoCard {...{ key: id, ...restProps }} />
          ))}
        </StyledWrapper>
      )}

      {externalLinks?.length && <LinksColumn items={externalLinks} titleToken={localizationKey("Related Articles:")} />}
    </>
  )
}

export default GettingStartedStepThree
GettingStartedStepThree.propTypes = {
  activeStep: PropTypes.string.isRequired,
  deviceVideos: PropTypes.array,
  externalLinks: PropTypes.array,
  onTaskCompleted: PropTypes.func.isRequired,
  stepsCompleted: PropTypes.shape(),
  permissionRequired: PropTypes.shape({ name: PropTypes.string.isRequired, allowed: PropTypes.bool.isRequired })
    .isRequired,
}
