import { useEffect } from "react"
import { fetchAvailablePatchVersions } from "js/includes/common/client"
import { useMountedState } from "js/includes/common/hooks"
import { localizationKey, reportErrorAndShowMessage } from "js/includes/common/utils"

const useAvailableVersions = (takenVersions, platform = "iOS") => {
  const [versions, setVersions] = useMountedState([])
  const [isLoading, setIsLoading] = useMountedState(false)

  const formatVersions = versions =>
    versions.map(productVersion => ({
      ...productVersion,
      labelText: productVersion.version,
      value: productVersion.version,
    }))

  useEffect(() => {
    const getAvailableVersions = async () => {
      setIsLoading(true)
      try {
        const { response } = await fetchAvailablePatchVersions(platform)
        const filteredVersions = response.filter(({ version }) => !takenVersions.includes(version))
        const versionFormatted = formatVersions(filteredVersions)
        setVersions(versionFormatted)
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Failed to fetch available versions"))
        setVersions([])
      } finally {
        setIsLoading(false)
      }
    }
    getAvailableVersions()
  }, [platform, setVersions, setIsLoading, takenVersions])
  return { versions, isLoading }
}
export default useAvailableVersions
