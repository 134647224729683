//@flow
import qs from "qs"
import { Base64 } from "js-base64"
import {
  redirectToLogin,
  ninjaReportError,
  isNotNilOrEmpty,
  assignOwnPropertiesInPlace,
} from "js/includes/common/utils"
import { pick, propOr } from "ramda"
import { NinjaNotAuthenticatedError } from "js/includes/common/types"
import { promptUserForMfa } from "js/includes/common/services/mfa"
import { getServerCallPrefix } from "js/state/selectors/application/serverCallPrefix"

export const errorStatuses = [
  400,
  403,
  404,
  405,
  406,
  408,
  409,
  413,
  414,
  415,
  422,
  423,
  429,
  451,
  500,
  501,
  502,
  503,
  504,
]

export const getAuthQuery = (responseJson, mfacode, _publicKeyCredentialEncoded) => {
  const token = responseJson.loginToken
  const u2fcode = _publicKeyCredentialEncoded && Base64.encode(JSON.stringify(_publicKeyCredentialEncoded))

  return token && (mfacode || u2fcode)
    ? `${qs.stringify({
        token,
        mfacode,
        u2fcode,
      })}`
    : ""
}

export const getPrefixedUrl = url => {
  const sessionPrefix = getServerCallPrefix(window.store?.getState()?.application)
  const prefix = sessionPrefix ? `/${sessionPrefix}` : "/ws"
  return `${prefix}${url}`
}

export const fetch = async (
  url,
  {
    options,
    redirectUserToLogin = true,
    headers = {},
    includeCredentials = true,
    useSessionPrefix = true,
    includeJsonContentType = true,
  } = {},
) => {
  const prefixedUrl = useSessionPrefix ? getPrefixedUrl(url) : url
  const response = await window.fetch(prefixedUrl, {
    headers: new Headers({
      ...(includeJsonContentType && { "Content-Type": "application/json" }),
      ...headers,
    }),
    ...(includeCredentials && { credentials: "include" }),
    ...options,
  })

  if (redirectUserToLogin && response.status === 401) {
    redirectToLogin()
    throw new NinjaNotAuthenticatedError()
  }

  if (errorStatuses.includes(response.status)) {
    const { url, status, statusText } = response
    const error = new Error(`${propOr("GET", "method", options)} ${url} ${status} (${statusText})`)
    error.response = response
    throw error
  }

  return response
}

export function parseJsonResponseFromBody(response) {
  return response?.headers?.get("content-type")?.includes("application/json") ? response.json() : {}
}

const insertMfaPath = (url = "") => {
  const queryIndex = url.indexOf("?")

  // Separate the path and query based on the first question mark
  const basePath = queryIndex !== -1 ? url.slice(0, queryIndex) : url
  const queryParams = queryIndex !== -1 ? url.slice(queryIndex) : ""

  // Remove any trailing slashes from the basePath to handle edge cases
  const normalizedBasePath = basePath.replace(/\/$/, "")

  // Insert "mfa" path
  const modifiedPath = `${normalizedBasePath}/mfa`

  // Reconstruct the URL with query parameters, if any
  return `${modifiedPath}${queryParams}`
}

export const fetchJson = async (
  url,
  {
    options = {},
    headers = {},
    redirectUserToLogin = true,
    includeCredentials = true,
    useSessionPrefix = true,
    includeJsonContentType = true,
    useMfaUrl = false,
  } = {},
) => {
  try {
    const response = await fetch(url, {
      options,
      redirectUserToLogin,
      headers,
      includeCredentials,
      useSessionPrefix,
      includeJsonContentType,
    })

    return parseJsonResponseFromBody(response)
  } catch (error) {
    if (error instanceof NinjaNotAuthenticatedError) {
      throw error
    }

    const errorResponseJson = await parseJsonResponseFromBody(error.response)

    if (errorResponseJson.resultCode === "MFA_REQUIRED") {
      const onSubmit = ({ mfaCode: mfacode, publicKeyCredentialEncoded }) => {
        const _url = useMfaUrl ? insertMfaPath(url) : url
        const hasQueryStringParams = _url.includes("?")
        const queryStringSeparator = hasQueryStringParams ? "&" : "?"
        return fetchJson(
          _url + queryStringSeparator + getAuthQuery(errorResponseJson, mfacode, publicKeyCredentialEncoded),
          { options },
        )
      }

      return promptUserForMfa(errorResponseJson, { onSubmit })
    }

    if (isNotNilOrEmpty(errorResponseJson)) {
      assignOwnPropertiesInPlace(errorResponseJson, error)

      const { Sentry } = window
      Sentry.setContext("JSON ERROR RESPONSE", errorResponseJson)
      if (errorResponseJson.incidentId) {
        Sentry.setTag("incidentId", errorResponseJson.incidentId)
      }
    }

    throw error
  }
}

export const upload = (
  url,
  {
    method = "POST",
    body = {},
    onProgressHandler,
    onAbortHandler,
    onSuccessHandler,
    U2fRequired,
    includeCredentials = true,
    useSessionPrefix = true,
    isMultipleFileUpload = false,
  },
) => {
  const xhr = new XMLHttpRequest()

  const promise = new Promise((resolve, reject) => {
    const formData = new FormData()

    for (let key in body) {
      if (isMultipleFileUpload) {
        formData.append("files", body[key])
      } else {
        formData.append(key, body[key])
      }
    }

    const prefixedUrl = useSessionPrefix ? getPrefixedUrl(url) : url

    xhr.addEventListener("load", async e => {
      try {
        if (xhr.status === 401) {
          throw new NinjaNotAuthenticatedError()
        }

        if (errorStatuses.includes(xhr.status)) {
          const { responseURL, status, statusText } = e.target
          const error = new Error(`Image upload to ${responseURL} ${status} (${statusText})`)
          error.response = e.target.response
          ninjaReportError(error)
          throw error
        }

        const responseJson = e.target?.response ? JSON.parse(e.target.response) : {}

        if (onSuccessHandler) {
          await onSuccessHandler(responseJson)
        }

        resolve(responseJson)
      } catch (error) {
        if (error instanceof NinjaNotAuthenticatedError) {
          throw error
        }

        const responseJson = e.target?.response ? JSON.parse(e.target.response) : {}

        if (responseJson.resultCode === "MFA_REQUIRED") {
          const onSubmit = ({ mfaCode: mfacode, publicKeyCredentialEncoded }) => {
            const hasQueryStringParams = prefixedUrl.includes("?")
            const queryStringSeparator = hasQueryStringParams ? "&" : "?"
            xhr.open(
              method,
              prefixedUrl + queryStringSeparator + getAuthQuery(responseJson, mfacode, publicKeyCredentialEncoded),
              true,
            )
            xhr.withCredentials = includeCredentials
            xhr.send(formData)
          }

          return promptUserForMfa(responseJson, { onSubmit }).catch(reject)
        }

        reject(pick(["responseURL", "status", "statusText", "response"], e.target || {}))
      }
    })

    if (onProgressHandler) {
      xhr.upload.addEventListener("progress", onProgressHandler, false)
    }

    if (onAbortHandler) {
      xhr.upload.addEventListener(
        "abort",
        async () => {
          await onAbortHandler()
          resolve()
        },
        false,
      )
    }

    xhr.upload.addEventListener("timeout", reject, false)
    xhr.upload.addEventListener("error", reject, false)

    xhr.open(method, prefixedUrl, true)

    if (U2fRequired && includeCredentials) {
      xhr.withCredentials = includeCredentials
    }

    xhr.send(formData)
  })

  return { xhr, promise }
}
