import { useRef, useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons"
import { HoverDropdown, Text, Tooltip } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { getLocalStorageParsedObject, isUpKey } from "js/includes/common/utils"
import { Flex } from "js/includes/components/Styled"
import SubNav from "./SubNav"
import MainNavButton from "./MainNavButton"
import { getLocalStorageKey, sidebarHoverDropdownWidth } from "./common"

const StyledWrapper = styled.div`
  margin-bottom: ${sizer(1)};
  overflow: hidden;

  ${({ theme, active }) => active && `background-color: ${theme.colorThemeBackgroundSelected};`}
`

const actionIconStyle = theme => css`
  background: none;
  border: none;
  color: ${theme.colorThemeText};

  &:focus-visible {
    outline: none;
    color: ${theme.colorForegroundFocus};
  }
`

const SubNavDrawer = ({
  icon,
  label,
  id,
  collapsed,
  navRenderer,
  hoverNavItemsRenderer,
  actionIcon,
  actionIconTooltip,
  onClickAction,
  active,
}) => {
  const localStorageKey = getLocalStorageKey(`${id}.open`)
  const openFromLocalStorage = getLocalStorageParsedObject(localStorageKey)
  const actionIconRef = useRef()
  const [isOpen, setIsOpen] = useState(openFromLocalStorage)

  const buttonRenderer = ({ isOpen: hoverDropdownIsOpen } = {}) => (
    <MainNavButton
      {...{
        icon,
        label,
        collapsed,
        active: hoverDropdownIsOpen ? true : active,
        onClick: () =>
          setIsOpen(isOpen => {
            const newValue = !isOpen
            window.localStorage.setItem(localStorageKey, newValue)

            return newValue
          }),
        ...(isOpen &&
          actionIcon && {
            actionIcons: [
              {
                sideOffset: 50,
                onClick: onClickAction,
                tooltip: actionIconTooltip,
                element: <FontAwesomeIcon icon={actionIcon} />,
              },
            ],
          }),
        ...(!collapsed && { secondaryIcon: isOpen ? faChevronUp : faChevronDown }),
      }}
    />
  )

  const actionIconButton = (
    <button
      ref={actionIconRef}
      onClick={onClickAction}
      onKeyDown={event => {
        if (isUpKey(event)) {
          event.stopPropagation()
        }
      }}
      css={actionIconStyle}
    >
      <FontAwesomeIcon icon={actionIcon} />
    </button>
  )

  const hoverDropdown = (
    <SubNav brandedBackground>
      <Flex marginBottom={sizer(2)} justifyContent="space-between">
        <Text color="colorThemeTextStrong" size="sm" lineHeight={1.2}>
          {label}
        </Text>
        {actionIcon &&
          (actionIconTooltip ? (
            <Tooltip label={actionIconTooltip} contentZIndex={99999} position="right" align="center" sideOffset={24}>
              {actionIconButton}
            </Tooltip>
          ) : (
            actionIconButton
          ))}
      </Flex>
      {hoverNavItemsRenderer()}
    </SubNav>
  )

  const hasActionIcon = !!actionIcon && !!onClickAction

  return collapsed ? (
    <HoverDropdown
      buttonRenderer={buttonRenderer}
      dropdownRenderer={() => hoverDropdown}
      placement="right"
      variant="secondary"
      {...(hasActionIcon && {
        onFirstItemTryingToGoUp: () => {
          actionIconRef.current.focus()
        },
      })}
      fullWidth
      dropdownMargin
      dropdownWidth={sidebarHoverDropdownWidth}
    />
  ) : (
    <StyledWrapper {...{ active }}>
      {buttonRenderer()}
      {isOpen && navRenderer()}
    </StyledWrapper>
  )
}

export default SubNavDrawer
