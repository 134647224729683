import { fetchJson, localized, showErrorMessage } from "js/includes/common/utils"
import { initializeSentry } from "js/includes/common/services/sentry"

const SENTRY_FETCHING_STATE_ERROR_MESSAGE = "Error fetching sentry state"

export const fetchTechnicianSentryState = async () => {
  try {
    return await fetchJson("/appuser/sentry-state")
  } catch (error) {
    showErrorMessage(localized(SENTRY_FETCHING_STATE_ERROR_MESSAGE, error))
    return null
  }
}

export const updateTechnicianSentryState = async () => {
  return await fetchJson("/appuser/toggle-sentry-state", { options: { method: "POST" } })
}

export const fetchEndUserSentryState = async () => {
  try {
    return await fetchJson("/end-user/sentry-state")
  } catch (error) {
    showErrorMessage(localized(SENTRY_FETCHING_STATE_ERROR_MESSAGE, error))
    return null
  }
}

export const updateEndUserSentryState = async () => {
  return await fetchJson("/end-user/toggle-sentry-state", { options: { method: "POST" } })
}

export const fetchSentryState = async userType => {
  if (userType === "TECHNICIAN") {
    return await fetchTechnicianSentryState()
  } else if (userType === "END_USER") {
    return await fetchEndUserSentryState()
  }
}

export const handleSentryInitialization = async (isSentryEnabled, userType) => {
  if (!isSentryEnabled && window.Sentry?.close) {
    await window.Sentry.close(0)
    delete window.Sentry
  } else if (isSentryEnabled) {
    await initializeSentry({
      environment: window.store.getState().application.environment,
      sampleRate: 1,
      skipFetch: true,
      userType,
    })
  }
}
