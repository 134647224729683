import { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { Root, Trigger, Portal, Content, Provider } from "@radix-ui/react-tooltip"
import tokens from "@ninjaone/tokens"

const baseContentZIndex = 3000

const StyledBaseContent = styled(Content)`
  outline: none;
  padding-top: 1px; // avoids trigger outline from getting cutoff
  z-index: ${baseContentZIndex};
`

const StyledContent = styled("div")`
  border-radius: ${tokens.borderRadius[1]};
  border: ${({ theme }) => `1px solid ${theme.colorBorderWeak}`};
  background: ${({ theme }) => theme.colorBackgroundWidget};
  padding: ${tokens.spacing[1]};
  box-shadow: ${({ theme }) => theme.elevationStrong};
  cursor: default;
`

const StyledTrigger = styled(Trigger)`
  position: relative;
  cursor: pointer;
  border-radius: ${tokens.borderRadius[1]};

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }
`

const HoverTooltip = ({
  align = "center",
  alignOffset = 0,
  side = "bottom",
  sideOffset = 0,
  hideDelay = 100,
  openDelay = 500,
  children,
  contentRenderer,
  portal = true,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const openTimeoutRef = useRef(null)
  const closeTimeoutRef = useRef(null)

  useEffect(() => {
    return () => {
      clearTimeout(openTimeoutRef.current)
      clearTimeout(closeTimeoutRef.current)
    }
  }, [])

  const _setIsOpen = openState => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current)
    }
    if (openTimeoutRef.current) {
      clearTimeout(openTimeoutRef.current)
    }
    setIsOpen(openState)
  }

  const handleOpen = () => {
    _setIsOpen(true)
  }

  const openViaTimeout = () => {
    openTimeoutRef.current = setTimeout(() => {
      _setIsOpen(true)
    }, openDelay)
  }

  const closeViaTimeout = () => {
    if (openTimeoutRef.current) {
      clearTimeout(openTimeoutRef.current)
    }
    closeTimeoutRef.current = setTimeout(() => {
      _setIsOpen(false)
    }, hideDelay)
  }

  const content = (
    <StyledBaseContent
      {...{
        align,
        alignOffset,
        avoidCollisions: true,
        side,
        sideOffset,
        onMouseEnter: handleOpen,
        onMouseLeave: event => {
          closeViaTimeout()
        },
        onEscapeKeyDown: () => {
          _setIsOpen(false)
        },
      }}
    >
      <StyledContent>{contentRenderer({ closeAction: () => _setIsOpen(false) })}</StyledContent>
    </StyledBaseContent>
  )

  return (
    <Provider>
      <Root open={isOpen}>
        <StyledTrigger
          {...{
            onFocus: handleOpen,
            onBlur: () => {
              _setIsOpen(false)
            },
            onMouseEnter: openViaTimeout,
            onMouseLeave: event => {
              closeViaTimeout()
            },
            onClick: () => {
              if (isOpen) {
                _setIsOpen(false)
              }
            },
            "data-ninja-hover-popover-trigger": "",
            asChild: true,
          }}
        >
          {children}
        </StyledTrigger>
        {portal ? <Portal>{content}</Portal> : content}
      </Root>
    </Provider>
  )
}

HoverTooltip.propTypes = {
  align: PropTypes.oneOf(["start", "center", "end"]),
  alignOffset: PropTypes.number,
  side: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  sideOffset: PropTypes.number,
  hideDelay: PropTypes.number,
  openDelay: PropTypes.number,
  children: PropTypes.node.isRequired,
  contentRenderer: PropTypes.func.isRequired,
  portal: PropTypes.bool,
}
export default HoverTooltip
